import { FuseNavigation } from '@fuse/types';
import { ApiService } from '../service/api.service';
import { Injectable } from '@angular/core';
import { PermissionsService } from '../service/permissions.service';
import { FuseNavigationService } from '../../@fuse/components/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  status = true;
  rgChildItem: FuseNavigation[] = [];
  deChildItem: FuseNavigation[] = [];
  reportChildItem: FuseNavigation[] = [];

  constructor(private apiService: ApiService, private prmsn: PermissionsService, private _fuseNavigationService: FuseNavigationService) {
    // status = localStorage.getItem('loginStatus');
    //
    //
    // if (status == "true") {
    //   this.setNavConfig();
    // }

  }

  public navigation: FuseNavigation[] = [];

  async setNavConfig() {

    if(!await this.prmsn.isAccessPerticularPermission('reports')){

      this.getReportNavigation();
    }

    if(!await this.prmsn.isAccessPerticularPermission('registers')){
      if(this.status) {
        this.getRegisterNavigation();
        this.status = false;
      }

    }

    if(!await this.prmsn.isAccessPerticularPermission('master-data-management')){

      this.getDataEntityNavigation();
    }
    this.navigation = [
      {

        id: 'SystemProfileManagement',
        title: 'System Profile Management',
        type: 'collapsable',
        icon: 'email',
        hidden: await this.prmsn.isAccessGroupPermission(null, 'system-profile'),
        children: [
          {
            id: 'roles',
            title: 'Roles',
            type: 'item',
            icon: 'email',
            url: '/system-profile/roles',
            hidden: await this.prmsn.isAccessGroupPermission('system-profile','roles')

          },
          {
            id: 'users',
            title: 'Users',
            type: 'item',
            icon: 'email',
            url: '/system-profile/users',
            hidden: await this.prmsn.isAccessGroupPermission('system-profile', 'users')
          },
          {
            id: 'change-user-password',
            title: 'Change User Password',
            type: 'item',
            icon: 'email',
            url: '/system-profile/change-user-password',
            hidden: await this.prmsn.isAccessGroupPermission('system-profile','change-user-password')
          },
          {
            id: 'forgot-password-requests',
            title: 'Forgot Password Requests',
            type: 'item',
            icon: 'email',
            url: '/system-profile/forgot-password-requests',
            hidden: await this.prmsn.isAccessGroupPermission('system-profile', 'forgot-password-requests')
          },
          {
            id: 'permissions-role',
            title: 'Permissions-Role',
            type: 'item',
            icon: 'email',
            url: '/system-profile/permissions-role',
            hidden: await this.prmsn.isAccessGroupPermission('system-profile','permissions-role')
          },
          {
            id: 'permissions-group',
            title: 'Permissions-Group',
            type: 'item',
            icon: 'email',
            url: '/system-profile/permissions-group',
            hidden: await this.prmsn.isAccessGroupPermission('system-profile','permissions-group')
          },
          {
            id: 'related-permissions',
            title: 'Related Permissions',
            type: 'item',
            icon: 'email',
            url: '/system-profile/related-permissions',
            hidden: await this.prmsn.isAccessGroupPermission('system-profile','related-permissions')
          },
          {
            id: 'audit-logs',
            title: 'Audit Logs',
            type: 'item',
            icon: 'email',
            url: '/system-profile/audit-logs',
            hidden: await this.prmsn.isAccessGroupPermission('system-profile','audit-logs')
          },
          {
            id: 'request-logs',
            title: 'Request Logs',
            type: 'item',
            icon: 'email',
            url: '/system-profile/request-logs',
            hidden: await this.prmsn.isAccessGroupPermission('system-profile','request-logs')
          }
          //     {
          //       id: 'permissions',
          //       title: 'Permissions',
          //       type: 'item',
          //       icon: 'email',
          //       url: '/swm/permissions'
          //     }
          //   ],
          // },
          // {

          //   id: 'my-profile',
          //   title: 'My-Profile',
          //   type: 'collapsable',
          //   icon: 'email',
          //   children: [
          //     {
          //       id: 'attendance',
          //       title: 'Attendance',
          //       type: 'item',
          //       icon: 'email',
          //       url: 'my-profile/attendance'

          //     },
          //     {
          //       id: 'basic-information',
          //       title: 'Basic-Information',
          //       type: 'item',
          //       icon: 'email',
          //       url: 'my-profile/basic-information'
          //     }

        ]
      },
      {

        id: 'state-profiling',
        title: 'State Profiling',
        type: 'collapsable',
        icon: 'dashboard',
        hidden: await this.prmsn.isAccessGroupPermission(null,'state_profiling'),
        children: [
          {
            id: 'state-management',
            title: 'State Management',
            type: 'item',
            icon: 'map',
            url: 'state-profiling/state-management',
            hidden: await this.prmsn.isAccessGroupPermission('state_profiling','state_management')
          },
          {
            id: 'division-management',
            title: 'Division Management',
            type: 'item',
            icon: 'map',
            url: 'state-profiling/division-management',
            hidden: await this.prmsn.isAccessGroupPermission('state_profiling','division_management')
          },
          {
            id: 'district-management',
            title: 'District Management',
            type: 'item',
            icon: 'map',
            url: 'state-profiling/district-management',
            hidden: await this.prmsn.isAccessGroupPermission('state_profiling','district_management')
          },
          {
            id: 'region-management',
            title: 'Region Management',
            type: 'collapsable',
            icon: 'donut_small',
            hidden: await this.prmsn.isAccessGroupPermission('state_profiling','region_management'),
            children: [
              // {
              //   id: 'hierarchies',
              //   title: 'Hierarchies',
              //   type: 'item',
              //   icon: 'email',
              //   url: 'state-profiling/region-management/hierarchies'
              // },
              {
                id: 'region-type',
                title: 'Region Type',
                type: 'item',
                icon: 'donut_small',
                url: 'state-profiling/region-management/region-type',
                hidden: await this.prmsn.isAccessGroupPermission('region_management','region_type')
              },
              {
                id: 'region',
                title: 'Region',
                type: 'item',
                icon: 'donut_small',
                url: 'state-profiling/region-management/region',
                hidden: await this.prmsn.isAccessGroupPermission('region_management','region')
              },
              {
                id: 'region-hierarchy',
                title: 'Region Hierarchy',
                type: 'item',
                icon: 'donut_small',
                url: 'state-profiling/region-management/region-hierarchy',
                hidden: await this.prmsn.isAccessGroupPermission('region_management','region_hierarchy')
              }
            ]
          },
          {
            id: 'scheme-management',
            title: 'Scheme Management',
            type: 'collapsable',
            icon: 'layers',
            hidden: await this.prmsn.isAccessGroupPermission('state_profiling','scheme_management'),
            children: [
              {
                id: 'manage-scheme',
                title: 'Scheme',
                type: 'item',
                icon: 'layers',
                url: 'state-profiling/scheme-management/manage-scheme',
                hidden: await this.prmsn.isAccessGroupPermission('scheme_management','manage_scheme')
              },
              {
                id: 'scheme-category',
                title: 'Scheme Category',
                type: 'item',
                icon: 'layers',
                url: 'state-profiling/scheme-management/scheme-category',
                hidden: await this.prmsn.isAccessGroupPermission('scheme_management','scheme_category')
              }
            ]
          },
          {
            id: 'ulb-management',
            title: 'ULB Management',
            type: 'item',
            icon: 'view_quilt',
            url: 'state-profiling/ulb-management',
            hidden: await this.prmsn.isAccessGroupPermission('state_profiling','ulb_management')
          },
          {
            id: 'ulb-type',
            title: 'ULB Type',
            type: 'item',
            icon: 'view_quilt',
            url: 'state-profiling/ulb-type',
            hidden: await this.prmsn.isAccessGroupPermission('ulb_management','ulb_type')
          }
        ]
      },
      {
        id: 'ulb-profiling',
        title: 'ULB Profiling',
        type: 'collapsable',
        icon: 'view_quilt',
        hidden: await this.prmsn.isAccessGroupPermission(null, 'ulb_profiling'),
        children: [
          {
            id: 'route-management',
            title: 'Route Management',
            type: 'collapsable',
            icon: 'call_split',
            hidden: await this.prmsn.isAccessGroupPermission('ulb_profiling','route_management'),
            children: [
              {
                id: 'route type',
                title: 'Route Type',
                type: 'item',
                icon: 'trending_up',
                url: 'ulb-profiling/route-management/route-type',
                hidden: await this.prmsn.isAccessGroupPermission('route_management','route_type')
              },
              {
                id: 'route-category',
                title: 'Route Category',
                type: 'item',
                icon: 'trending_up',
                url: 'ulb-profiling/route-management/route-category',
                hidden: await this.prmsn.isAccessGroupPermission('route_management','route_category')
              },
              {
                id: 'route_category_route_type',
                title: 'Route Category Route Type',
                type: 'item',
                icon: 'trending_up',
                url: 'ulb-profiling/route-management/route-category-route-type',
                hidden: await this.prmsn.isAccessGroupPermission('route_management','route_category_route_type')
              },
              {
                id: 'route',
                title: 'Route',
                type: 'item',
                icon: 'trending_up',
                url: 'ulb-profiling/route-management/route',
                hidden: await this.prmsn.isAccessGroupPermission('route_management','route')
              },
              {
                id: 'route-region',
                title: 'Route Region',
                type: 'item',
                icon: 'trending_up',
                url: 'ulb-profiling/route-management/route-region',
                hidden: await this.prmsn.isAccessGroupPermission('route_management','route-region')
              },
              {
                id: 'all-routes-map',
                title: 'All Routes Map',
                type: 'item',
                icon: 'trending_up',
                url: 'ulb-profiling/route-management/all-routes-map',
                hidden: await this.prmsn.isAccessGroupPermission('route_management','all-routes-map')
              }
            ]
          },
          {
            id: 'ulb-scheme',
            title: 'ULB Scheme',
            type: 'item',
            icon: 'view_quilt',
            url: 'ulb-profiling/ulb-scheme',
            hidden: await this.prmsn.isAccessGroupPermission('ulb_profiling','route_category_route_type')
          },
          {
            id: 'ulb-setup',
            title: 'ULB Setup',
            type: 'item',
            icon: 'view_quilt',
            url: 'master-data-management/store-entity-data/18'
          },
          {
            id: 'configuration',
            title: 'Configuration',
            type: 'collapsable',
            icon: 'assignment_late',
            hidden: await this.prmsn.isAccessGroupPermission('ulb_profiling','configuration'),
            children: [
              {
                id: 'collection-type',
                title: 'Collection Type',
                type: 'item',
                icon: 'home',
                url: 'ulb-profiling/information-capture/collection-type',
                hidden: await this.prmsn.isAccessGroupPermission('ulb_profiling','collection_type')
              }
            ]

          }
        ]
      },
      {
        id: 'asset-management',
        title: 'Asset Management',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        hidden: await this.prmsn.isAccessGroupPermission(null,'asset_management'),
        children: [
          {
            id: 'asset-category',
            title: 'Asset Category',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'asset-management/asset-category',
            hidden: await this.prmsn.isAccessGroupPermission('asset_management','asset_category')
          },
          {
            id: 'asset-type',
            title: 'Asset Type',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'asset-management/asset-type',
            hidden: await this.prmsn.isAccessGroupPermission('asset_management','asset_type')
          },
          {
            id: 'asset-conditions',
            title: 'Asset Conditions',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'asset-management/asset-conditions',
            hidden: await this.prmsn.isAccessGroupPermission('asset_management','asset_conditions')
          },
          {
            id: 'asset',
            title: 'Asset',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'asset-management/asset',
            hidden: await this.prmsn.isAccessGroupPermission('asset_management','asset')
          },
          {
            id: 'asset-assignment',
            title: 'Asset Assignment',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'asset-management/asset-assignment',
            hidden: await this.prmsn.isAccessGroupPermission('asset_management','asset_assignment')
          },
          {
            id: 'asset-assignment-history',
            title: 'Asset Assignment History',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'asset-management/asset-assignment-history',
            hidden: await this.prmsn.isAccessGroupPermission('asset_management','asset_assignment_history')
          }
        ]
      },
      {
        id: 'user-charges',
        title: 'User Charges',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        hidden: await this.prmsn.isAccessGroupPermission(null,'user_charges'),
        children: [
          {
            id: 'consumer',
            title: 'Consumer',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/consumer-details',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','consumer_details')
          },
          {
            id: 'property-list',
            title: 'Pending Approval',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/property-list',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','pending_approval')
          },
          {
            id: 'bill-configuration',
            title: 'Bill Configuration',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/bill-configuration',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','bill_configuration')
          },
          {
            id: 'payment-report',
            title: 'Payment Report',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/payment-report',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','payment_report')
          },
          {
            id: 'pending-payment-report',
            title: 'Pending Payment Report',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/pending-payment-report',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','pending_payment_report')
          },
          {
            id: 'demand-report',
            title: 'Demand Report',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/demand-report',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','demand_report')
          },
          {
            id: 'transaction-report',
            title: 'Transaction Report',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/transaction-report',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','transaction_report')
          },
          {
            id: 'payment-summary-report',
            title: 'Payment Summary Report',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/payment-summary-report',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','payment_summary_report')
          },
          {
            id: 'assigned-handover-requests',
            title: 'Assigned Handover Requests',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/assigned-handover-requests',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','assigned_handover_requests')
          },
          {
            id: 'cash-collection-summary-report',
            title: 'Cash Collection Summary Report',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/cash-collection-summary-report',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','cash_collection_summary_report')
          },
          {
            id: 'handover-request-detail-report',
            title: 'Handover Request Detail Report',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/handover-request-detail-report',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','handover_request_detail_report')
          },
          {
            id: 'property-type',
            title: 'Property Type',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/property-type',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','property_type')
          },
          {
            id: 'property-use-type',
            title: 'Property Use Type',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'user-charges/property-use-type',
            hidden: await this.prmsn.isAccessGroupPermission('user_charges','property_use_type')
          }
        ]
      },
      {
        id: 'leave-management',
        title: 'Leave Management',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        hidden: await this.prmsn.isAccessGroupPermission(null,'leave_management'),
        children: [
          {
            id: 'leave-type',
            title: 'Leave Type',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'leave-management/leave-type',
            hidden: await this.prmsn.isAccessGroupPermission('leave_management','leave-type')
          },
          {
            id: 'leave-status',
            title: 'Leave Status',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'leave-management/leave-status',
            hidden: await this.prmsn.isAccessGroupPermission('leave_management','leave_status')
          },
          {
            id: 'leaves',
            title: 'Leaves',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'leave-management/leave',
            hidden: await this.prmsn.isAccessGroupPermission('leave_management','leave')
          },
          {
            id: 'employee-wise-leave-status',
            title: 'Employee Wise Leave Status',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'leave-management/employee-wise-leave-status',
            hidden: await this.prmsn.isAccessGroupPermission('leave_management','employee-wise-leave-status')
          },
          {
            id: 'employee-type-to-leave-type-mapping',
            title: 'Employee Type to Leave Type Mapping',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'leave-management/employee-type-to-leave-type-mapping',
            hidden: await this.prmsn.isAccessGroupPermission('leave_management','employee_type_to_leave_type_mapping')
          },
        ]
      },
      {
        id: 'internal-announcements',
        title: 'Internal Announcements',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        hidden: await this.prmsn.isAccessGroupPermission(null,'internal_announcements'),
        children: [
          {
            id: 'announcements',
            title: 'Announcements',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'internal-announcements/announcements',
            hidden: await this.prmsn.isAccessGroupPermission('internal_announcements','Announcements')
          },
          {
            id: 'reports',
            title: 'Reports',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'internal-announcements/reports',
            hidden: await this.prmsn.isAccessGroupPermission('internal_announcements','reports')
          },
        ]
      },
      {
        id: 'vehicle-procurement',
        title: 'Vehicle Procurement',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        hidden: await this.prmsn.isAccessGroupPermission(null,'vehicle_procurement'),
        children: [
          {
            id: 'acquisition-request',
            title: 'Acquisition Request',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'vehicle-procurement/acquisition-request',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle_procurement','acquisition_request')
          },
          {
            id: 'proposal',
            title: 'Proposal',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'vehicle-procurement/proposal-component',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle_procurement','proposal_component')
          },
          {
            id: 'order',
            title: 'Order',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'vehicle-procurement/order',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle_procurement','order')
          },
          {
            id: 'payment-history',
            title: 'Payment History',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'vehicle-procurement/payment-history',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle_procurement','payment-history')
          }
        ]
      },
      {
        id: 'fuel-management',
        title: 'Fuel Management',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        hidden: await this.prmsn.isAccessGroupPermission(null,'fuel-management'),
        children: [
          {
            id: 'dashboard',
            title: 'dashboard',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'fuel-management/dashboard',
            hidden: await this.prmsn.isAccessGroupPermission('fuel-management','dashboard')
          },
          {
            id: 'consumption',
            title: 'Consumption',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'fuel-management/consumption',
            hidden: await this.prmsn.isAccessGroupPermission('fuel-management','consumption')
          },
          {
            id: 'fuel_stations',
            title: 'Fuel Stations',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'fuel-management/fuel-stations',
            hidden: await this.prmsn.isAccessGroupPermission('fuel-management','fuel_stations')
          },
        ]
      },
      {
        id: 'register-management',
        title: 'Register Management',
        type: 'collapsable',
        icon: 'library_books',
        hidden: await this.prmsn.isAccessGroupPermission(null,'register_management'),
        children: [
          {
            id: 'register-dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'assignment',
            url: 'register-management/register-dashboard',
            hidden: await this.prmsn.isAccessGroupPermission('register_management','register_dashboard')
          },
          {
            id: 'registers',
            title: 'Registers',
            type: 'item',
            icon: 'assignment',
            url: 'register-management/registers',
            hidden: await this.prmsn.isAccessGroupPermission('register_management','registers')
          },
          {
            id: 'questions',
            title: 'Questions',
            type: 'item',
            icon: 'not_listed_location',
            url: 'register-management/questions',
            hidden: await this.prmsn.isAccessGroupPermission('register_management','questions')
          },
          {
            id: 'question-categories',
            title: 'Question Category',
            type: 'item',
            icon: 'assignment',
            url: 'register-management/question-categories',
            hidden: await this.prmsn.isAccessGroupPermission('register_management','question_categories')
          },
          {
            id: 'register-list',
            title: 'Register List',
            type: 'item',
            icon: 'assignment',
            url: 'registers/register-list',
            hidden: await this.prmsn.isAccessGroupPermission('register_management','register_list')
          },
          {
            id: 'register-categories',
            title: 'Register Category',
            type: 'item',
            icon: 'assignment',
            url: 'register-management/register-categories',
            hidden: await this.prmsn.isAccessGroupPermission('register_management','register_categories')
          },
          {
            id: 'register-types',
            title: 'Register Type',
            type: 'item',
            icon: 'assignment',
            url: 'register-management/register-types',
            hidden: await this.prmsn.isAccessGroupPermission('register_management','register_types')
          }
        ]
      },
      {
        id: 'resource',
        title: 'Resource Management',
        type: 'collapsable',
        icon: 'assistant',
        hidden: await this.prmsn.isAccessGroupPermission(null,'resource'),
        children: [
          {
            id: 'employee-management',
            title: 'Employee Management',
            type: 'collapsable',
            icon: 'recent_actors',
            hidden: await this.prmsn.isAccessGroupPermission('resource','employee_management'),
            children: [
              {
                id: 'department-management',
                title: 'Department',
                type: 'item',
                icon: 'view_compact',
                url: 'resource/employee-management/department-management',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','department_management')
              },
              {
                id: 'employee-import',
                title: 'Employee',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/employee-profile',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','employee_profile')
              },
              {
                id: 'employee-to-poi-mapping',
                title: 'Employee To POI Mapping',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/employee-to-poi-mapping',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','employee_to_poi_mapping')
              },
              {
                id: 'employee-import',
                title: 'Employee-Import',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/employee-import',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','employee-import')
              },
              {
                id: 'employee-type',
                title: 'Employee Type',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/employee-type',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','employee_type')
              },
              {
                id: 'designation',
                title: 'Designation',
                type: 'item',
                icon: 'person_pin',
                url: 'resource/employee-management/designation',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','designation')
              },
              {
                id: 'employee-shift',
                title: 'Employee Shift',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/employee-shift',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','employee_shift')
              },
              {
                id: 'department-designation',
                title: 'Department-Designation',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/department-designation',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','department-designation')
              },
              {
                id: 'employee-distributed-items',
                title: 'Employee Distributed Items',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/employee-distributed-items',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','employee-distributed-items')
              },
              {
                id: 'adhoc-assignment-employee',
                title: 'Ad-Hoc Assignment Employee',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/adhoc-assignment-employee',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','adhoc-assignment-employee')
              },
              {
                id: 'change-reporting-manager',
                title: 'Change Reporting Manager',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/change-reporting-manager',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','change-reporting-manager')
              },
              {
                id: 'designation-item',
                title: 'Designation Item',
                type: 'item',
                icon: 'portrait',
                url: 'resource/employee-management/designation-item',
                hidden: await this.prmsn.isAccessGroupPermission('employee_management','designation-item')
              },

            ]
          },
          {
            id: 'vehicle-management',
            title: 'Vehicle Management',
            type: 'collapsable',
            icon: 'local_shipping',
            hidden: await this.prmsn.isAccessGroupPermission('resource','vehicle_management'),
            children: [
              {
                id: 'vehicle-master',
                title: 'Vehicle',
                type: 'item',
                icon: 'local_shipping',
                url: 'resource/vehicle-management/vehicle-master',
                hidden: await this.prmsn.isAccessGroupPermission('resource','vehicle_master')
              },
              {
                id: 'vehicle-capacity',
                title: 'Vehicle Capacity',
                type: 'item',
                icon: 'local_shipping',
                url: 'resource/vehicle-management/vehicle-capacity',
                hidden: await this.prmsn.isAccessGroupPermission('resource','vehicle_capacity')
              },
              {
                id: 'vehicle-make',
                title: 'Vehicle Make',
                type: 'item',
                icon: 'local_shipping',
                url: 'resource/vehicle-management/vehicle-make',
                hidden: await this.prmsn.isAccessGroupPermission('resource','vehicle_make')
              },
              {
                id: 'vehicle-type',
                title: 'Vehicle Type',
                type: 'item',
                icon: 'local_shipping',
                url: 'resource/vehicle-management/vehicle-type',
                hidden: await this.prmsn.isAccessGroupPermission('resource','vehicle_type')
              },
              {
                id: 'fuel-type',
                title: 'Fuel Type',
                type: 'item',
                icon: 'local_gas_station',
                url: 'resource/vehicle-management/fuel-type',
                hidden: await this.prmsn.isAccessGroupPermission('resource','fuel_type')
              },
              {
                id: 'vehicle-region',
                title: 'Vehicle Region',
                type: 'item',
                icon: 'local_shipping',
                url: 'resource/vehicle-management/vehicle-region',
                hidden: await this.prmsn.isAccessGroupPermission('resource','vehicle-region')
              }
            ]
          },
          {
            id: 'inventory-management',
            title: 'Inventory Management',
            type: 'collapsable',
            icon: 'chrome_reader_mode',
            hidden: await this.prmsn.isAccessGroupPermission('resource','inventory_management'),
            children: [
              {
                id: 'inventory-categories',
                title: 'Inventory Category',
                type: 'item',
                icon: 'chrome_reader_mode',
                url: 'resource/inventory-management/inventory-category',
                hidden: await this.prmsn.isAccessGroupPermission('inventory_management','inventory_category')
              },
              {
                id: 'inventory-type',
                title: 'Inventory Type',
                type: 'item',
                icon: 'chrome_reader_mode',
                url: 'resource/inventory-management/inventory-type',
                hidden: await this.prmsn.isAccessGroupPermission('inventory_management','inventory_type')
              },
              {
                id: 'inventory-manage',
                title: 'Inventory',
                type: 'item',
                icon: 'chrome_reader_mode',
                url: 'resource/inventory-management/manage-inventory',
                hidden: await this.prmsn.isAccessGroupPermission('inventory_management','manage_inventory')
              },
              {
                id: 'plastic-bins',
                title: 'Plastic Bins',
                type: 'item',
                icon: 'chrome_reader_mode',
                url: 'resource/inventory-management/manage-plastic-bins',
                hidden: await this.prmsn.isAccessGroupPermission('inventory_management','manage-plastic_bins')
              },
              {
                id: 'metal-bins',
                title: 'Metal Bins',
                type: 'item',
                icon: 'chrome_reader_mode',
                url: 'resource/inventory-management/manage-metal-bins',
                hidden: await this.prmsn.isAccessGroupPermission('inventory_management','manage_metal_bins')
              },
              {
                id: 'disinfection-machine-management',
                title: 'Disinfection Machine Management',
                type: 'item',
                icon: 'chrome_reader_mode',
                url: 'resource/inventory-management/manage-disinfection-machine',
                hidden: await this.prmsn.isAccessGroupPermission('inventory_management','manage_disinfection_machine')
              }

            ]
          }
        ]
      },
      {
        id: 'master-data-management',
        title: 'Master Data Management',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        hidden: await this.prmsn.isAccessGroupPermission(null,'master_data_management'),
        children: [
          {
            id: 'data-list',
            title: 'Data List',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'master-data-management/data-list',
            hidden: await this.prmsn.isAccessGroupPermission('master_data_management','data_list')
          },
          {
            id: 'data-entity-categories',
            title: 'Data Category',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'master-data-management/data-category',
            hidden: await this.prmsn.isAccessGroupPermission('master_data_management','data_category')
          }

        ]
      },
      {
        id: 'report-management',
        title: 'Report Management',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        hidden: await this.prmsn.isAccessGroupPermission(null,'reports'),
        children: [
          {
            id: 'report-configuration',
            title: 'Report Configuration',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'reports/reports-configuration',
            hidden: await this.prmsn.isAccessGroupPermission('reports','reports_configuration')
          }
        ]
      },
      {
        id: 'contract-management',
        title: 'Contract Management',
        type: 'collapsable',
        icon: 'email',
        hidden: await this.prmsn.isAccessGroupPermission(null,'contract_management'),
        children: [
          {
            id: 'create-contract',
            title: 'Contract',
            type: 'item',
            icon: 'email',
            url: 'contract-management/contract',
            hidden: await this.prmsn.isAccessGroupPermission('contract_management','contract')
          },

          {
            id: 'tender',
            title: 'Tender',
            type: 'item',
            icon: 'email',
            url: 'contract-management/tender',
            hidden: await this.prmsn.isAccessGroupPermission('contract_management','tender')
          },
          {
            id: 'vendor-management',
            title: 'Vendor',
            type: 'item',
            icon: 'email',
            url: 'contract-management/vendor',
            hidden: await this.prmsn.isAccessGroupPermission('contract_management','vendor')
          },
          {
            id: 'service-type',
            title: 'Service Type',
            type: 'item',
            icon: 'email',
            url: 'contract-management/service-type',
            hidden: await this.prmsn.isAccessGroupPermission('contract_management','service_type')
          },
          {
            id: 'contract-status',
            title: 'Contract Status',
            type: 'item',
            icon: 'email',
            url: 'contract-management/contract-status',
            hidden: await this.prmsn.isAccessGroupPermission('contract_management','contract_status')
          }
        ]
      },
      {
        id: 'public-notification',
        title: 'Public Notification',
        type: 'collapsable',
        icon: 'chat',
        hidden: await this.prmsn.isAccessGroupPermission(null, 'public_notification'),
        children: [
          {
            id: 'notification-configuration',
            title: 'Notification Configuration',
            type: 'item',
            icon: 'chat',
            url: 'public-notification/notification-configuration',
            hidden: await this.prmsn.isAccessGroupPermission('public_notification','notification_configuration')
          },
          {
            id: 'notification-type',
            title: 'Notification Type',
            type: 'item',
            icon: 'chat',
            url: 'public-notification/notification-type',
            hidden: await this.prmsn.isAccessGroupPermission('public_notification','notification_type')
          },
          {
            id: 'notification',
            title: 'Notification',
            type: 'item',
            icon: 'chat',
            url: 'public-notification/notification',
            hidden: await this.prmsn.isAccessGroupPermission('public_notification','notification')
          }
          // {
          //   id: 'testComp',
          //   title: 'Test',
          //   type: 'item',
          //   icon: 'email',
          //   url: 'public-notification/testComp'
          // }
        ]
      },
      {
        id: 'citizen-verification-management',
        title: 'Citizen Verification Management',
        type: 'collapsable',
        icon: 'departure_board',
        hidden: await this.prmsn.isAccessGroupPermission(null,'citizen_verification_management'),
        children: [
          // {
          //   id: 'daily-planner',
          //   title: 'Daily Planner',
          //   type: 'collapsable',
          //   icon: 'date_range',
          //   children: [
          //     {
          //       id: 'manage-shifts',
          //       title: 'Shifts',
          //       type: 'item',
          //       icon: 'departure_board',
          //       url: 'vtms/daily-planner/manage-shifts'
          //     }
          //   ],
          // }
          {
            id: 'rule-configuration',
            title: 'Rule Configuration',
            type: 'item',
            icon: 'departure_board',
            url: 'citizen-verification-management/rule-configuration',
            hidden: await this.prmsn.isAccessGroupPermission('citizen_verification_management','rule_configuration')
          },
          {
            id: 'collection-point-verification',
            title: 'Collection Point Verification',
            type: 'item',
            icon: 'departure_board',
            url: 'citizen-verification-management/feedback-data',
            hidden: await this.prmsn.isAccessGroupPermission('citizen_verification_management','feedback_data')
          },
          {
            id: 'collection-point-location',
            title: 'Collection Point Location',
            type: 'item',
            icon: 'departure_board',
            url: 'citizen-verification-management/collection-point-location',
            hidden: await this.prmsn.isAccessGroupPermission('citizen_verification_management','collection_point_location')
          }
        ]
      },
      {
        id: 'har-din-6-bin',
        title: 'HarDin6Bin',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        hidden: await this.prmsn.isAccessGroupPermission(null,'har-din-6-bin'),
        children: [
          {
            id: 'har-din-6-bin',
            title: 'HarDin6Bin',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'har-din-6-bin-management/har-din-6-bin',
            hidden: await this.prmsn.isAccessGroupPermission(null,'har-din-6-bin')
          },
          {
            id: 'data-entity-register-mapping',
            title: 'Data Entity Register Mapping',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'har-din-6-bin-management/data-entity-register-mapping',
            hidden: await this.prmsn.isAccessGroupPermission('har-din-6-bin','data-entity-register-mapping')
          },
          {
            id: 'gvp-cleaning-reports',
            title: 'GVP Cleaning Report',
            type: 'item',
            icon: 'settings_system_daydream',
            url: 'har-din-6-bin-management/gvp-cleaning-reports',
            hidden: await this.prmsn.isAccessGroupPermission(null,'gvp-cleaning-reports')
          }
        ]
      },
      // {
      //   id: 'har-din-6-bin',
      //   title: 'HarDin6Bin',
      //   type: 'item',
      //   icon: 'grade',
      //   url: 'har-din-6-bin',
      //   hidden: await this.prmsn.isAccessGroupPermission(null,'har-din-6-bin')
      // },
      {
        id: 'register-list',
        title: 'Register',
        type: 'collapsable',
        icon: 'assignment',
        children: this.rgChildItem,
        hidden: await this.prmsn.isAccessGroupPermission(null,'registers')
      },
      {
        id: 'entity-list',
        title: 'Data Entity',
        type: 'collapsable',
        icon: 'settings_system_daydream',
        children: this.deChildItem,
        hidden: await this.prmsn.isAccessGroupPermission(null,'master-data-management')
      },
      {
        id: 'ComplianceManagement',
        title: 'Compliance Management',
        type: 'collapsable',
        icon: 'email',
        hidden: await this.prmsn.isAccessGroupPermission(null,'compliance_management'),
        children: [
          {
            id: 'Dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'email',
            hidden: await this.prmsn.isAccessGroupPermission('compliance_management','dashboard')
          },
          {
            id: 'ComplianceResults',
            title: 'Compliance Results',
            type: 'collapsable',
            icon: 'email',
            hidden: await this.prmsn.isAccessGroupPermission('compliance_management','compliance_results'),
            children: [
              {
                id: 'SwachhBharatMission',
                title: 'State Admin',
                type: 'item',
                icon: 'email',
                url: 'compliance-management/compliance-results/state-admin'
              },
              {
                id: 'swachh-survekshan2020',
                title: 'ULB Admin',
                type: 'item',
                icon: 'email',
                url: 'compliance-management/compliance-results/ulb-admin',
                hidden: await this.prmsn.isAccessGroupPermission('compliance_results','ulb_admin')
              },
              {
                id: 'rule1.3',
                title: 'Rule 1.3',
                type: 'item',
                icon: 'email',
                url: 'compliance-management/compliance-results/1.3'
              },
              {
                id: 'rule1.9',
                title: 'Rule 1.9',
                type: 'item',
                icon: 'email',
                url: 'compliance-management/compliance-results/compliance-result'
              },
              {
                id: 'groups',
                title: 'Groups',
                type: 'item',
                icon: 'email',
                url: '/compliance-management/rules-configuration/compliance-groups'
              },
              {
                id: 'sections',
                title: 'Sections',
                type: 'item',
                icon: 'email',
                url: '/compliance-management/rules-configuration/compliance-sections'
              },
              {
                id: 'rules',
                title: 'Rules',
                type: 'item',
                icon: 'email',
                url: 'compliance-management/rules-configuration/rule'
              }
            ]
          }


        ]
      },
      {
        id: 'CitizenEngagement',
        title: 'Citizen Engagement',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'citizen_engagement'),
        children: [
          {
            id: 'citizen-feedback',
            title: 'Entity Feedback',
            type: 'item',
            icon: 'grade',
            url: 'citizen_engagement/citizen-feedback',
            hidden: await this.prmsn.isAccessGroupPermission('citizen_engagement','citizen_feedback')
          }
        ]
      },
      {
        id: 'qr-management',
        title: 'QR Management',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'qr_management'),
        children: [
          {
            id: 'qr-generator-list',
            title: 'Generator',
            type: 'item',
            icon: 'grade',
            url: 'qr-management/qr-generator-list',
            hidden: await this.prmsn.isAccessGroupPermission('qr_management','qr_generator_list')
          },
          {
            id: 'qr-template-list',
            title: 'Template',
            type: 'item',
            icon: 'grade',
            url: 'qr-management/qr-template-list',
            hidden: await this.prmsn.isAccessGroupPermission('qr_management','qr_template_list')
          },
          {
            id: 'qr-list',
            title: 'QR List',
            type: 'item',
            icon: 'grade',
            url: 'qr-management/qr-list',
            hidden: await this.prmsn.isAccessGroupPermission('qr_management','qr_list')
          }
        ]
      },
      {
        id: 'report-list',
        title: 'Reports',
        type: 'collapsable',
        icon: 'grade',
        children: this.reportChildItem,
        hidden: await this.prmsn.isAccessGroupPermission(null,'reports')
      },
      {
        id: 'beat-planning',
        title: 'Beat Planning',
        type: 'item',
        icon: 'grade',
        url: 'beat-planning',
        hidden: await this.prmsn.isAccessGroupPermission(null,'beat_planning')
      },
      {
        id: 'job-scheduler',
        title: 'Job Scheduler',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'job_scheduler'),
        children: [
          {
            id: 'jobschedulerlist',
            title: 'Scheduler',
            type: 'item',
            icon: 'grade',
            url: 'job-scheduler/job-scheduler-list',
            hidden: await this.prmsn.isAccessGroupPermission('job_scheduler','job_scheduler_list')
          },
          {
            id: 'jobconfiguration',
            title: 'Configuration',
            type: 'item',
            icon: 'grade',
            url: 'job-scheduler/job-configuration',
            hidden: await this.prmsn.isAccessGroupPermission('job_scheduler','job_configuration')
          },
          {
            id: 'jobcategory',
            title: 'Category',
            type: 'item',
            icon: 'grade',
            url: 'job-scheduler/job-category',
            hidden: await this.prmsn.isAccessGroupPermission('job_scheduler','job_category')
          },
          {
            id: 'jobtype',
            title: 'Job Type',
            type: 'item',
            icon: 'grade',
            url: 'job-scheduler/job-type',
            hidden: await this.prmsn.isAccessGroupPermission('job_scheduler','job_type')
          },
          {
            id: 'categorytype',
            title: 'Job Category - Job Type',
            type: 'item',
            icon: 'grade',
            url: 'job-scheduler/job-type-job-category-assignment',
            hidden: await this.prmsn.isAccessGroupPermission('job_scheduler','job_type_job_category_assignment')
          },
          {
            id: 'routecategoryjobtype',
            title: 'Route Category - Job Type',
            type: 'item',
            icon: 'grade',
            url: 'job-scheduler/job-type-route-category-assignment',
            hidden: await this.prmsn.isAccessGroupPermission('job_scheduler','job_type_job_category_assignment')
          }
        ]
      },
      {
        id: 'maintenance-scheduler',
        title: 'Maintenance Scheduler',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'maintenance-scheduler'),
        children: [
          {
            id: 'maintenanceschedulerlist',
            title: 'Scheduler',
            type: 'item',
            icon: 'grade',
            url: 'maintenance-scheduler/maintenance-scheduler-list',
            hidden: await this.prmsn.isAccessGroupPermission('maintenance-scheduler','maintenance-scheduler-list')
          },
          {
            id: 'maintenanceconfiguration',
            title: 'Configuration',
            type: 'item',
            icon: 'grade',
            url: 'maintenance-scheduler/maintenance-configuration',
            hidden: await this.prmsn.isAccessGroupPermission('maintenance-scheduler','maintenance-configuration')
          }
        ],
      },
      {
        id: 'beat-scheduler',
        title: 'Beat Scheduler',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'beat-scheduler'),
        children: [
          {
            id: 'beatschedulerlist',
            title: 'Scheduler',
            type: 'item',
            icon: 'grade',
            url: 'beat-scheduler/beat-scheduler-list',
            hidden: await this.prmsn.isAccessGroupPermission('beat-scheduler','beat-scheduler-list')
          },
          {
            id: 'beatconfiguration',
            title: 'Configuration',
            type: 'item',
            icon: 'grade',
            url: 'beat-scheduler/beat-configuration',
            hidden: await this.prmsn.isAccessGroupPermission('beat-scheduler','beat-configuration')
          }
        ],
      },
      {
        id: 'grievance_management',
        title: 'Grievance Management',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'grievance_management'),
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'grade',
            url: 'grievance-management/grievance-management-dashboard',
            hidden: await this.prmsn.isAccessGroupPermission('grievance_management','grievance-management-dashboard'),
          },
          {
            id: 'grievance_rule',
            title: 'Grievance SLA',
            type: 'item',
            icon: 'grade',
            url: 'grievance-management/grievance-rule-list',
            hidden: await this.prmsn.isAccessGroupPermission('grievance_management','grievance_rule_list'),
          },
          {
            id: 'grievance__category',
            title: 'Category',
            type: 'item',
            icon: 'grade',
            url: 'grievance-management/grievance-category',
            hidden: await this.prmsn.isAccessGroupPermission('grievance_management','grievance_category'),
          },
          {
            id: 'add__complaint',
            title: 'Add Complaint',
            type: 'item',
            icon: 'grade',
            url: 'grievance-management/grievance-complaint',
            hidden: await this.prmsn.isAccessGroupPermission('grievance_management','grievance-complaint'),
          },
          {
            id: 'gravience_template_list',
            title: 'Template',
            type: 'item',
            icon: 'grade',
            url: 'grievance-management/grievance-template-list',
            hidden: await this.prmsn.isAccessGroupPermission('grievance_management','grievance_template_list'),
          },
          {
            id: 'gravience_report',
            title: 'Report',
            type: 'item',
            icon: 'grade',
            url: 'grievance-management/grievance-report',
            hidden: await this.prmsn.isAccessGroupPermission('grievance_management','grievance_report'),
          },
          {
            id: 'gravience_status',
            title: 'Status Management',
            type: 'item',
            icon: 'grade',
            url: 'grievance-management/grievance-status',
            hidden: await this.prmsn.isAccessGroupPermission('grievance_management','grievance_status'),
          },
          {
            id: 'grievance_derived_status',
            title: 'Derived Status Management',
            type: 'item',
            icon: 'grade',
            url: 'grievance-management/grievance-derived-status',
            hidden: await this.prmsn.isAccessGroupPermission('grievance_management','grievance_derived_status'),
          },
          {
            id: 'gravience_status_workflow',
            title: 'Status Workflow Management',
            type: 'item',
            icon: 'grade',
            url: 'grievance-management/grievance-status-workflow-list',
            hidden: await this.prmsn.isAccessGroupPermission('grievance_management','grievance_status_workflow'),
          },


        ],
      },
      // {
      //   id: 'employee-attendance-management',
      //   title: 'Employee Management',
      //   type: 'collapsable',
      //   icon: 'grade',
      //   hidden: await this.prmsn.isAccessGroupPermission(null,'employee_attendance_management'),
      //   children: [
      //     {
      //       id: 'viewattendance',
      //       title: 'View Attendance',
      //       type: 'item',
      //       icon: 'grade',
      //       url: '/employee-management/view-attendance',
      //       hidden: await this.prmsn.isAccessGroupPermission('employee_attendance_management','view_attendance')

      //     },
      //     {
      //       id: 'leave',
      //       title: 'Leave',
      //       type: 'item',
      //       icon: 'grade',
      //       url: '/employee-management/leave',
      //       hidden: await this.prmsn.isAccessGroupPermission('employee_attendance_management','leave')

      //     },
      //     {
      //       id: 'leave-management',
      //       title: 'Leave Management',
      //       type: 'collapsable',
      //       icon: 'grade',
      //       hidden: await this.prmsn.isAccessGroupPermission('employee_attendance_management','leave_management'),
      //       children: [
      //         {
      //           id: 'leave-type',
      //           title: 'Leave Type',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/employee-management/leave-management/leave-type',
      //           hidden: await this.prmsn.isAccessGroupPermission('leave_management','leave_type')

      //         },
      //         {
      //           id: 'leave-allotment',
      //           title: 'Leave Allotment',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/employee-management/leave-management/leave-allotment',
      //           hidden: await this.prmsn.isAccessGroupPermission('leave_management','leave_allotment')

      //         },
      //         {
      //           id: 'leave-status',
      //           title: 'Leave Status',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/employee-management/leave-management/leave-status',
      //           hidden: await this.prmsn.isAccessGroupPermission('leave_management','leave_status')

      //         },
      //       ]
      //     },
      //     {
      //       id: 'payroll-management',
      //       title: 'Payroll Management',
      //       type: 'item',
      //       icon: 'grade',
      //       url: '/employee-management/payroll-management',
      //       hidden: await this.prmsn.isAccessGroupPermission('employee_attendance_management','payroll_management')

      //     },
      //     {
      //       id: 'tracking',
      //       title: 'Tracking',
      //       type: 'item',
      //       icon: 'grade',
      //       url: '/employee-management/tracking',
      //       hidden: await this.prmsn.isAccessGroupPermission('employee_attendance_management','tracking')

      //     }
      //   ]
      // },
      {
        id: 'system-configurations',
        title: 'System Configurations',
        type: 'item',
        icon: 'grade',
        url: 'system-configurations',
        hidden: await this.prmsn.isAccessGroupPermission(null,'system_configurations')
      },

      // {
      //   id: 'playback',
      //   title: 'Playback',
      //   type: 'item',
      //   icon: 'grade',
      //   url: 'playback',
      //   hidden: await this.prmsn.isAccessPerticularPermission('playback')
      // },

      // {
      //   id: 'vehiclelocation',
      //   title: 'Vehicle Location',
      //   type: 'item',
      //   icon: 'grade',
      //   url: 'vehicle-location',
      //   hidden: await this.prmsn.isAccessPerticularPermission('vehicle-location')
      // },

      {
        id: 'vehicle-tracking',
        title: 'Vehicle Tracking',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'vehicle-tracking'),
        children: [
          // {
          //   id: 'vehicle-location-old',
          //   title: 'Vehicle Location(old)',
          //   type: 'item',
          //   icon: 'grade',
          //   url: '/vehicle-tracking/vehicle-location',
          //   hidden: await this.prmsn.isAccessGroupPermission('vehicle-tracking','vehicle-location')
          // },
          {
            id: 'vehicle-location',
            title: 'Vehicle Location',
            type: 'item',
            icon: 'grade',
            url: '/vehicle-tracking/vehicle-location-new',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle-tracking','vehicle-location-new')
          },
          {
            id: 'vehicle-movement-report',
            title: 'Vehicle Movement Report',
            type: 'item',
            icon: 'grade',
            url: '/vehicle-tracking/vehicle-movement-report',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle-tracking','vehicle-movement-report')
          },
          {
            id: 'playback',
            title: 'Playback',
            type: 'item',
            icon: 'grade',
            url: 'playback',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle-tracking','playback')
          },
          {
            id: 'stoppage-report',
            title: 'Stoppage Report',
            type: 'item',
            icon: 'grade',
            url: '/vehicle-tracking/stoppage-report',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle-tracking','stoppage-report')
          },
          {
            id: 'stoppage-report-25',
            title: 'Stoppage Report 25',
            type: 'item',
            icon: 'grade',
            url: '/vehicle-tracking/stoppage-report-25',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle-tracking','stoppage-report')
          },
          {
            id: 'vehicle-status-report',
            title: 'Vehicle Status Report',
            type: 'item',
            icon: 'grade',
            url: '/vehicle-tracking/vehicle-status-report',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle-tracking','vehicle-status-report')
          },
          {
            id: 'overspeeding-alerts',
            title: 'Overspeeding Alerts',
            type: 'item',
            icon: 'grade',
            url: '/vehicle-tracking/overspeeding-alerts',
            hidden: await this.prmsn.isAccessGroupPermission('vehicle-tracking','overspeeding-alerts')
          }


        ]
      },

      {
        id: 'employee-tracking',
        title: 'Employee Tracking',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'employee_tracking'),
        children: [
          {
            id: 'employee-in-out',
            title: 'Employee In/Out',
            type: 'item',
            icon: 'grade',
            url: '/employee-tracking/employee-in-out',
            hidden: await this.prmsn.isAccessGroupPermission('employee_tracking','employee_in_out')
          },
          {
            id: 'employee-monthly-in-out',
            title: 'Employee Monthly In/Out',
            type: 'item',
            icon: 'grade',
            url: '/employee-tracking/employee-monthly-in-out',
            hidden: await this.prmsn.isAccessGroupPermission('employee_tracking','employee_monthly_in_out')
          },
          {
            id: 'work_visit_summary_report',
            title: 'Work Visit Summary Report',
            type: 'item',
            icon: 'grade',
            url: '/employee-tracking/work-visit-summary-report',
            hidden: await this.prmsn.isAccessGroupPermission('employee_tracking','work_visit_summary_report')
          },
          {
            id: 'employee_leave_summary_report',
            title: 'Employee Leave Summary',
            type: 'item',
            icon: 'grade',
            url: '/employee-tracking/employee-leave-summary-report',
            hidden: await this.prmsn.isAccessGroupPermission('employee_tracking','employee_leave_summary_report')
          },
          {
            id: 'employee-location',
            title: 'Employee Location',
            type: 'item',
            icon: 'grade',
            url: '/employee-tracking/employee-location',
            hidden: await this.prmsn.isAccessGroupPermission('employee_tracking','employee_location')
          }
        ]
      },

      {
        id: 'rfid-reports',
        title: 'RFID & POI Reports',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'rfid-reports'),
        children: [
          {
            id: 'vehicleinout',
            title: 'Vehicle Exit/Entry Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/event-report',
            hidden: await this.prmsn.isAccessGroupPermission('rfid-reports','event-report')
          },
          {
            id: 'rfidBinUnloading',
            title: 'Bin Unloading Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/rfid-bin-unloading-report',
            hidden: await this.prmsn.isAccessGroupPermission('rfid-reports','rfid-bin-unloading-report')
          },

          {
            id: 'bincleaning',
            title: 'Bin Cleaning Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/bin-cleaning-report',
            hidden: await this.prmsn.isAccessGroupPermission('rfid-reports','bin-cleaning-report')
          },
          {
            id: 'bineventreport',
            title: 'BOV Bins Deployment',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/bov-bins-deployment',
            hidden: await this.prmsn.isAccessGroupPermission('rfid-reports','bov_bins_deployment')
          },
          {
            id: 'poi_event_report',
            title: 'POI Event Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/poi-event-report',
            hidden: await this.prmsn.isAccessGroupPermission('rfid-reports','poi-event-report')
          },
          {
            id: 'rfid_raw_data_report',
            title: 'RFID Raw Data Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/rfid-raw-data-report',
            hidden: await this.prmsn.isAccessPerticularPermission('rfid-raw-data-report')
          },
          {
            id: 'facility-entry-exit-report',
            title: ' Facility Raw Data Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/facility-entry-exit-report',
            hidden: await this.prmsn.isAccessPerticularPermission('facility-entry-exit-report')
          },
          {
            id: 'facility-event-report',
            title: ' Facility Event Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/facility-event-report',
            hidden: await this.prmsn.isAccessPerticularPermission('facility-event-report')
          },
          {
            id: 'market-poi-report',
            title: 'Market POI Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/market-poi-report',
            hidden: await this.prmsn.isAccessPerticularPermission('market-poi-report')
          },
          {
            id: 'disinfection-machine-event-report',
            title: 'Disinfection Machine Event Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/disinfection-machine-event-report',
            hidden: await this.prmsn.isAccessPerticularPermission('disinfection-machine-event-report')
          },
          {
            id: 'vehicle-event-report',
            title: 'Vehicle Event Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/vehicle-event-report',
            hidden: await this.prmsn.isAccessPerticularPermission('vehicle-event-report')
          },
          {
            id: 'facility-last-event-report',
            title: 'Facility Last Event Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/facility-last-event-report',
            hidden: await this.prmsn.isAccessPerticularPermission('facility-last-event-report')
          },
          {
            id: 'rc-last-event-report',
            title: 'RC Last Event Report',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/rc-last-event-report',
            hidden: await this.prmsn.isAccessPerticularPermission('rc-last-event-report')
          },
          {
            id: 'bin-unloading-map',
            title: 'Bin Unloading Map',
            type: 'item',
            icon: 'grade',
            url: '/rfid-reports/bin-unloading-map',
            hidden: await this.prmsn.isAccessPerticularPermission('bin-unloading-map')
          }
        ]
  },

      // {
      //   id: 'weighbridge-report',
      //   title: 'Weighbridge Report',
      //   type: 'collapsable',
      //   icon: 'grade',
      //   hidden: await this.prmsn.isAccessPerticularPermission('weighbridge-report'),
      //   children: [
      //     {
      //       id: 'report',
      //       title: 'Report',
      //       type: 'item',
      //       icon: 'grade',
      //       url: '/weighbridge-report/report',
      //       hidden: await this.prmsn.isAccessPerticularPermission('report')
      //     },
      //   ]
      // },

      {
        id: 'attendance-reports',
        title: 'Attendance Reports',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'attendance-reports'),
        children: [
          {
            id: 'employeeinoutcount',
            title: 'Employee Swipe Summary',
            type: 'item',
            icon: 'grade',
            url: '/attendance-reports/employee-in-out-count',
            hidden: await this.prmsn.isAccessGroupPermission('attendance-reports','employee-in-out-count')
          },
           {
            id: 'employeeinoutreport',
            title: 'Employee In/Out Report',
            type: 'item',
            icon: 'grade',
            url: '/attendance-reports/employee-in-out-report',
            hidden: await this.prmsn.isAccessPerticularPermission('employee-in-out-report')
          },
          {
            id: 'attendancelivecount',
            title: 'Attendance Live Count',
            type: 'item',
            icon: 'grade',
            url: '/attendance-reports/attendance-live-count',
            hidden: await this.prmsn.isAccessGroupPermission('attendance-reports','attendance-live-count')
          },
          {
            id: 'employeesregistered',
            title: 'Employees Registered',
            type: 'item',
            icon: 'grade',
            url: '/attendance-reports/employees-registered',
            hidden: await this.prmsn.isAccessPerticularPermission('employees-registered')
          },
          {
            id: 'attendanceRegionReport',
            title: 'Attendance Region Report',
            type: 'item',
            icon: 'grade',
            url: '/attendance-reports/attendance-region-report',
            hidden: await this.prmsn.isAccessGroupPermission('attendance-reports','attendance-region-report')
          },
          {
            id: 'attendancesummary',
            title: 'Attendance Summary Report',
            type: 'item',
            icon: 'grade',
            url: '/attendance-reports/attendance-summary-report',
            hidden: await this.prmsn.isAccessGroupPermission('attendance-reports','attendance-summary-report')
          },
          {
            id: 'datewiseattendancesummary',
            title: 'Date Wise Attendance Summary Report',
            type: 'item',
            icon: 'grade',
            url: '/attendance-reports/date-wise-attendance-summary-report',
            hidden: await this.prmsn.isAccessGroupPermission('attendance-reports','date-wise-attendance-summary-report')
          },
          {
            id: 'daysAttendanceCount',
            title: 'Days Attendance Count',
            type: 'item',
            icon: 'grade',
            url: '/attendance-reports/days-attendance-count',
            hidden: await this.prmsn.isAccessGroupPermission('attendance-reports','days-attendance-count')
          },
          {
            id: 'tempAttendanceReport',
            title: 'Attendance Report(Temp)',
            type: 'item',
            icon: 'grade',
            url: '/attendance-reports/temp-attendance-report',
            hidden: await this.prmsn.isAccessGroupPermission('attendance-reports','temp-attendance-report')
          },

        ]
      },
      {
        id: 'kpi',
        title: 'KPI',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'kpi'),
        children: [
          {
            id: 'kpi-report',
            title: 'KPI Report',
            type: 'item',
            icon: 'grade',
            url: '/kpi/kpi-report',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','kpi-report'),
          },
          {
            id: 'store-kpi',
            title: 'Store KPI',
            type: 'item',
            icon: 'grade',
            url: '/kpi/store-kpi',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','store-kpi'),
          },
          {
            id: 'add-dashboard-kpi',
            title: 'Add Dashboard KPI',
            type: 'item',
            icon: 'grade',
            url: '/kpi/store-gcc-kpi-values',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','store-gcc-kpi-values'),
          },
          {
            id: 'approve-dashboard-kpi',
            title: 'Approve Dashboard KPI',
            type: 'item',
            icon: 'grade',
            url: '/kpi/approve-gcc-kpi-values',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','approve-gcc-kpi-values'),
          },
          {
            id: 'uplaod-kpi-report',
            title: 'Upload Kpi Report',
            type: 'item',
            icon: 'grade',
            url: '/kpi/upload-kpi-report',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','upload-kpi-report'),
          },
          {
            id: 'uplaod-report',
            title: 'Upload Kpi Report (New)',
            type: 'item',
            icon: 'grade',
            url: '/kpi/upload-report',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','upload-report'),
          },
          {
            id: 'uplaod-kpi-temp-report',
            title: 'Upload Kpi Temp Report',
            type: 'item',
            icon: 'grade',
            url: '/kpi/upload-kpi-temp-report',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','upload_kpi_temp_report'),
          },
          {
            id: 'view-report',
            title: 'KPI REPORT',
            type: 'item',
            icon: 'grade',
            url: '/kpi/view-report',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','view-report'),
          },
          {
            id: 'view-kpi-temp-report',
            title: 'View Kpi Temp Report',
            type: 'item',
            icon: 'grade',
            url: '/kpi/view-kpi-temp-report',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','temporary_kpi_report'),
          },
          {
            id: 'weighbridge-report',
            title: 'Weighbridge Report',
            type: 'item',
            icon: 'grade',
            url: '/kpi/weighbridge-report',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','weighbridge-report'),
          },
          {
            id: 'kpi-tasks',
            title: 'KPI Tasks',
            type: 'item',
            icon: 'grade',
            url: '/kpi/kpi-tasks',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','kpi-tasks'),
          },
          {
            id: 'kpi-issues-files-dir',
            title: 'KPI Issues Files Directory',
            type: 'item',
            icon: 'grade',
            url: '/kpi/kpi-issues-files-dir',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','kpi-issues-files-dir'),
          },
          {
            id: 'kpi-violations',
            title: 'KPI Violations',
            type: 'item',
            icon: 'grade',
            url: '/kpi/kpi-violations',
            hidden: await this.prmsn.isAccessGroupPermission('kpi','kpi-violations'),
          }
        ]
      },
      // {
      //   id: 'kpi-reports',
      //   title: 'KPI Reports',
      //   type: 'collapsable',
      //   icon: 'grade',
      //   hidden: await this.prmsn.isAccessGroupPermission(null,'kpi-reports'),
      //   children: [
      //     {
      //       id: 'primary-collection',
      //       title: 'Primary Collection',
      //       type: 'collapsable',
      //       icon: 'grade',
      //       children: [
      //         {
      //           id: 'minimum-requried-deployment-of-manpower',
      //           title: 'KPI 5.1',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi51'
      //         },
      //         {
      //           id: 'requried-deployment-e-rickshaws-as-per-approved-miop',
      //           title: 'KPI 5.2',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi52'
      //         },
      //         {
      //           id: 'collection-of-solid-waste-presegregated-from-household-tolerance-level',
      //           title: 'KPI 5.3.a',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi53a'
      //         },
      //         {
      //           id: 'collection-of-solid-waste-unsegregated-from-household-tolerance-level',
      //           title: 'KPI 5.3.b',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi53b'
      //         },
      //         {
      //           id: 'collection-of-solid-waste-from-market-areas',
      //           title: 'KPI 5.4',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi54'
      //         },
      //         {
      //           id: 'minimum-guaranteed-solid-waste-to-decentralized-processing-units-as-per-miop',
      //           title: 'KPI 5.5',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi55'
      //         },
      //         {
      //           id: 'collection-of-horticultural-waste-from-parks-and-rodeside',
      //           title: 'KPI 5.6',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi56'
      //         },
      //         {
      //           id: 'collection-deposition-of-domestic-hazardous-waste',
      //           title: 'KPI 5.7',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi57'
      //         },
      //         {
      //           id: 'provisioning-maintenance-of-separate-compartments-in-e-rickshaw',
      //           title: 'KPI 5.8',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi58'
      //         },
      //         {
      //           id: 'installation-of-Signage-boards-on-streets-and-establish-vantage-points',
      //           title: 'KPI 5.9',
      //           type: 'item',
      //           icon: 'grade',
      //           url: 'kpi-reports/primary-collection/kpi59'
      //         }
      //       ]
      //     },
      //     {
      //       id: 'street-sweeping-collection',
      //       title: 'Street Sweeping Collection',
      //       type: 'collapsable',
      //       icon: 'grade',
      //       children: [
      //         // {
      //         //   id: 'manual-timely-street-sweeping',
      //         //   title: 'KPI 6.1.a',
      //         //   type: 'item',
      //         //   icon: 'grade',
      //         //   url: '/kpi-reports/street-sweeping-collection/kpi61a'
      //         // },
      //         {
      //           id: 'mechanical-timely-street-sweeping',
      //           title: 'KPI 6.1.b',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/street-sweeping-collection/kpi61b'
      //         },
      //         {
      //           id: 'minimum-requried-deployment-of-manpower',
      //           title: 'KPI 6.2.a',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/street-sweeping-collection/kpi62a'
      //         },
      //         {
      //           id: 'small-big-mechanical-street-sweeper',
      //           title: 'KPI 6.3',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/street-sweeping-collection/kpi63'
      //         },
      //         {
      //           id: 'wheeled-bins-other-equipment',
      //           title: 'KPI 6.4',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/street-sweeping-collection/kpi64'
      //         },
      //         {
      //           id: 'transportation-of-desilted-waste-to-the-designated-location',
      //           title: 'KPI 6.5',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/street-sweeping-collection/kpi65'
      //         }
      //       ]
      //     },
      //     {
      //       id: 'secondary-collection-transportation',
      //       title: 'Secondary Collection',
      //       type: 'collapsable',
      //       icon: 'grade',
      //       children: [
      //         {
      //           id: 'clearing-and-disinfections-of-bins-as-per-miop',
      //           title: 'KPI 7.1',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/secondary-collection-transportation/kpi71'
      //         },
      //         {
      //           id: 'transportation-of-segregated-waste-via-required-deployment',
      //           title: 'KPI 7.2',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/secondary-collection-transportation/kpi72'
      //         },
      //         {
      //           id: 'minimum-required-deployment-of-manpower',
      //           title: 'KPI 7.3',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/secondary-collection-transportation/kpi73'
      //         },
      //         {
      //           id: 'designated-number-of-functional-closed-bins-in-specified-colour-for-wet-dry-street',
      //           title: 'KPI 7.4',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/secondary-collection-transportation/kpi74'
      //         },
      //         {
      //           id: 'bin-replacement-broken-damaged-within-24-hours',
      //           title: 'KPI 7.5',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/secondary-collection-transportation/kpi75'
      //         },
      //         {
      //           id: 'bin-washing-as-per-miop',
      //           title: 'KPI 7.6',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/secondary-collection-transportation/kpi76'
      //         },
      //         {
      //           id: 'provisioning-of-top-cover-for-secondary-c-and-t-vehicles',
      //           title: 'KPI 7.7',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/secondary-collection-transportation/kpi77'
      //         },
      //         {
      //           id: 'sweeping-of-beach-service-roads-and-waste-removal',
      //           title: 'KPI 7.8',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/secondary-collection-transportation/kpi78'
      //         },
      //         {
      //           id: 'supplying-segregated-minimum-guaranteed-solid-waste',
      //           title: 'KPI 7.9',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/secondary-collection-transportation/kpi79'
      //         }
      //       ]
      //     },
      //     {
      //       id: 'mis',
      //       title: 'MIS',
      //       type: 'collapsable',
      //       icon: 'grade',
      //       children: [
      //         {
      //           id: 'submission-of-reports-with-periodicity-format',
      //           title: 'KPI 8.1',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/mis/kpi81'
      //         },
      //         {
      //           id: 'provision-of-minimum-number-of-required-manpower-infrastructure',
      //           title: 'KPI 8.2',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/mis/kpi82'
      //         }
      //       ]
      //     },
      //     {
      //       id: 'complaint-redressal',
      //       title: 'Complaint Redressal',
      //       type: 'collapsable',
      //       icon: 'grade',
      //       children: [
      //         {
      //           id: 'redressal-of-all-complaints-within-6-hours',
      //           title: 'KPI 9.1',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/complaint-redressal/kpi91'
      //         },
      //         {
      //           id: 'redressal-of-complaints-for-replacement-retro-fitment-repairing',
      //           title: 'KPI 9.2',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/complaint-redressal/kpi92'
      //         },
      //         {
      //           id: 'functional-24-7-call-centre-ivrs',
      //           title: 'KPI 9.3',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/complaint-redressal/kpi93'
      //         }
      //       ]
      //     },
      //     {
      //       id: 'compliances',
      //       title: 'Compliances',
      //       type: 'collapsable',
      //       icon: 'grade',
      //       children: [
      //         {
      //           id: 'legal-compliances',
      //           title: 'KPI 10.1',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/compliances/kpi101'
      //         },
      //         {
      //           id: 'ehs-compliances',
      //           title: 'KPI 10.2',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/compliances/kpi102'
      //         },
      //         {
      //           id: 'non-mixing-of-c-d-with-solid-waste',
      //           title: 'KPI 10.3',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/compliances/kpi103'
      //         },
      //         {
      //           id: 'functional-24-7-call-centre-ivrs',
      //           title: 'KPI 10.4',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/compliances/kpi104'
      //         }
      //       ]
      //     },
      //     {
      //       id: 'iec-activities',
      //       title: 'iec-activities',
      //       type: 'collapsable',
      //       icon: 'grade',
      //       children: [
      //         {
      //           id: 'kpi111',
      //           title: 'KPI 11.1',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/iec-activities/kpi111'
      //         },
      //         {
      //           id: 'kpi112',
      //           title: 'KPI 11.2',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/iec-activities/kpi112'
      //         },
      //         {
      //           id: 'kpi113',
      //           title: 'KPI 11.3',
      //           type: 'item',
      //           icon: 'grade',
      //           url: '/kpi-reports/iec-activities/kpi113'
      //         }
      //       ]
      //     }
      //   ]
      // },

      {
        id: 'task-management',
        title: 'Task Management',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'task-management'),
        children: [
          {
            id: 'task-list',
            title: 'Task List',
            type: 'item',
            icon: 'grade',
            url: '/task-management/task-list',
            hidden: await this.prmsn.isAccessGroupPermission('task-management','task-list'),
          }
        ]
      },
      {
        id: 'pois-management',
        title: 'POI Management',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'pois-management'),
        children: [
          {
            id: 'poi-types',
            title: 'POI Types',
            type: 'item',
            icon: 'grade',
            url: '/pois-management/poi-types',
            hidden: await this.prmsn.isAccessGroupPermission('pois-management','pois-types'),
          },
          {
            id: 'pois',
            title: 'POIs',
            type: 'item',
            icon: 'grade',
            url: '/pois-management/pois',
            hidden: await this.prmsn.isAccessGroupPermission('pois-management','pois'),
          }
        ]
      },
      {
        id: 'PayrollManagement',
        title: 'Payroll Management',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'payroll-management'),
        children: [
          {
            id: 'payrollSetup',
            title: 'Payroll Setup',
            type: 'item',
            icon: 'grade',
            url: '/payroll-management/payroll-setup',
            hidden: await this.prmsn.isAccessGroupPermission('payroll-management','payroll-setup'),
          },
          {
            id: 'payrollSetup',
            title: 'Payroll List',
            type: 'item',
            icon: 'grade',
            url: '/payroll-management/payroll-list',
            hidden: await this.prmsn.isAccessGroupPermission('payroll-management','payroll-list'),
          }
        ]
      },
      {
        id: 'ItemDistribution',
        title: 'Item Distribution',
        type: 'collapsable',
        icon: 'grade',
        hidden: await this.prmsn.isAccessGroupPermission(null,'item-distribution'),
        children: [
          {
            id: 'requestList',
            title: 'Request List',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/request-list',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','request-list'),
          },
          {
            id: 'uploadRequest',
            title: 'Upload Request',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/upload-request',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','upload-request'),
          },
          {
            id: 'RequestsStatusReport',
            title: 'Requests Status Report',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/request-status-report',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','request-status-report'),
          },
          {
            id: 'RequestIssuanceSummary',
            title: 'Request Distribution Summary',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/request-issuance-summary-report',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','request-issuance-summary-report'),
          },
          {
            id: 'RequestIssuanceDetailed',
            title: 'Request Distribution Detailed',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/request-issuance-detail-report',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','request-issuance-detail-report'),
          },
          {
            id: 'readyToPickupRequestReport',
            title: 'Ready To Pickup Request Report',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/ready-to-pickup-report',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','ready-to-pickup-report'),
          },
          {
            id: 'readyToPickupRequestReport',
            title: 'Request vs Pending Detail Report',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/request-vs-pending-detail-report',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','request_vs_pending_detail_report'),
          },
          {
            id: 'returnItemList',
            title: 'Return Items List',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/return-item-list',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','return_item_list'),
          },
          {
            id: 'distributionSummaryReport',
            title: 'Distribution Summary Report',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/distribution-summary-report',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','distribution_summary_report'),
          },
          {
            id: 'distributionDetailReport',
            title: 'Distribution Details Report',
            type: 'item',
            icon: 'grade',
            url: '/item-distribution/distribution-detail-report',
            hidden: await this.prmsn.isAccessGroupPermission('item-distribution','distribution_detail_report'),
          }
        ]
      },
      {
        // id: 'Beats',
        // title: 'Beats',
        // type: 'collapsable',
        // icon: 'grade',
        // hidden: await this.prmsn.isAccessGroupPermission(null,'beats'),
        // children: [
        //   {
            id: 'beats',
            title: 'Beats',
            type: 'item',
            icon: 'grade',
            url: '/beats',
            hidden: await this.prmsn.isAccessGroupPermission('null','beats'),
        //   }
        // ]
      }


    ];

  //  const emptyMenu = this.navigation.find(res => (res.children && res.children.length > 0));
  // setTimeout(() => {
  //   this.navigation.map(res => {
  //     if(res.children && res.children.length == 0) {
  //      console.log('res',res);
  //     }
  //   });
  // }, 500);


    const currentNav = this._fuseNavigationService.getCurrentNavigation();
    if (currentNav && currentNav.length > 0) {
      this._fuseNavigationService.unregister('main-nav')
    }
    this._fuseNavigationService.register('main-nav', this.navigation);
    this._fuseNavigationService.setCurrentNavigation('main-nav');
  };


  async getRegisterNavigation() {

    this.rgChildItem = [];
    this.apiService.get('register_categories').subscribe(async res => {
      if (res.data != null) {


        for (let pNav of res.data) {

          var parentNavArray: FuseNavigation[] = [];

          // =====================
          //   If Data has Child
          // =====================

          if (pNav.childs.length > 0) {

            // ===================================
            //   LOOP TO FETCH LEVEL 1 CHILD INFO
            // ===================================

            for (let child1Nav of pNav.childs) {

              var Child1NavArray: FuseNavigation[] = [];

              // ============================
              //   If Child 1 has Sub Child
              // ============================

              if (child1Nav.childs.length > 0) {

                var Child2NavArray: FuseNavigation[] = [];

                // ===================================
                //   LOOP TO FETCH LEVEL 2 Child INFO
                // ===================================

                for (let child2Nav of child1Nav.childs) {

                  // ============================
                  //   If Child 2 has Sub Child
                  // ============================

                  if (child2Nav.childs.length > 0) {

                    for (var v = 0; v < child2Nav.childs.length; v++) {
                      var child2MenuItem = {
                        'id': child2Nav.childs[v].id,
                        'title': child2Nav.childs[v].name,
                        'type': 'item',
                        'icon': 'supervised_user_circle',
                        'url': 'registers/store-register-data/' + child2Nav.childs[v].id,
                        hidden: await this.prmsn.isAccessPerticularPermission('post_register_' + child2Nav.childs[v].id)
                      };

                      Child2NavArray.push(child2MenuItem);
                    }

                  }

                  // ============================
                  //   If Child 2 has Registers
                  // ============================

                  if (child2Nav.registers.length > 0) {
                    for (let w of child2Nav.registers) {
                      var deChild3MenuItem = {
                        'id': w.id,
                        'title': w.name,
                        'type': 'item',
                        'icon': 'supervised_user_circle',
                        'url': 'registers/store-register-data/' + w.id,
                        hidden: await this.prmsn.isAccessPerticularPermission('post_register_' + w.id)
                      };
                      Child2NavArray.push(deChild3MenuItem);
                    }
                  }

                  // ===========================================
                  //   Push Child / Registers to Parent Child
                  // ===========================================

                  var collapsableChild3MenuItem = {
                    'id': child2Nav.id,
                    'title': child2Nav.name,
                    'type': 'collapsable',
                    'icon': 'supervised_user_circle',
                    'children': Child2NavArray,
                    hidden: await this.prmsn.isAccessPerticularPermission('post_register_category_' + child2Nav.id)
                  };
                  Child1NavArray.push(collapsableChild3MenuItem);
                }
                console.log('Child 2 : ' + Child2NavArray);

              }

              // ============================
              //   If Child 1 has Registers
              // ============================

              if (child1Nav.registers.length > 0) {

                for (let y of child1Nav.registers) {
                  var Registers2MenuItem = {
                    'id': y.id,
                    'title': y.name,
                    'type': 'item',
                    'icon': 'supervised_user_circle',
                    'url': 'registers/store-register-data/' + y.id,
                    hidden: await this.prmsn.isAccessPerticularPermission('post_register_' + y.id)
                  };
                  Child1NavArray.push(Registers2MenuItem);
                }

              }

              // =====================================
              //   Push Child / Registers to Parent
              // =====================================


              var Child3MenuItem = {
                'id': child1Nav.id,
                'title': child1Nav.name,
                'type': 'collapsable',
                'icon': 'supervised_user_circle',
                'children': Child1NavArray,
                hidden: await this.prmsn.isAccessPerticularPermission('post_register_category_' + child1Nav.id)
              };
              if(Child1NavArray && Child1NavArray.length) {
                parentNavArray.push(Child3MenuItem);
              }
            }

          }

          // =========================
          //   If Data has Registers
          // =========================

          if (pNav.registers.length > 0) {
            for (let x of pNav.registers) {
              var deMenuItem = {
                'id': x.id,
                'title': x.name,
                'type': 'item',
                'icon': 'supervised_user_circle',
                'url': 'registers/store-register-data/' + x.id,
                hidden: await this.prmsn.isAccessPerticularPermission('post_register_' + x.id)
              };
              parentNavArray.push(deMenuItem);
            }
          }

          // =======================================
          //   Push Child / Registers to Main Menu
          // ========================================

          var collapsableChild1MenuItem = {
            'id': pNav.id,
            'title': pNav.name,
            'type': 'collapsable',
            'icon': 'supervised_user_circle',
            'children': parentNavArray,
            hidden: await this.prmsn.isAccessPerticularPermission('post_register_category_' + pNav.id)
          };

          this.rgChildItem.push(collapsableChild1MenuItem);

        }

        // this.nav.push(menuItem);
         this._fuseNavigationService.updateNavigationItem('register-list', this.rgChildItem);
      }
    });

  }

  async getDataEntityNavigation() {

    this.deChildItem = [];
    this.apiService.get('category_with_data_entity').subscribe(async res => {
      if (res.data != null) {


        for (let pNav of res.data) {

          var parentNavArray: FuseNavigation[] = [];

          // =====================
          //   If Data has Child
          // =====================

          if (pNav.child.length > 0) {

            // ===================================
            //   LOOP TO FETCH LEVEL 1 CHILD INFO
            // ===================================

            for (let child1Nav of pNav.child) {

              var Child1NavArray: FuseNavigation[] = [];

              // ============================
              //   If Child 1 has Sub Child
              // ============================

              if (child1Nav.child.length > 0) {

                var Child2NavArray: FuseNavigation[] = [];

                // ===================================
                //   LOOP TO FETCH LEVEL 2 Child INFO
                // ===================================

                for (let child2Nav of child1Nav.child) {

                  // ============================
                  //   If Child 2 has Sub Child
                  // ============================

                  if (child2Nav.child.length > 0) {

                    for (var v = 0; v < child2Nav.child.length; v++) {
                      var child2MenuItem = {
                        'id': child2Nav.child[v].id,
                        'title': child2Nav.child[v].title,
                        'type': 'item',
                        'icon': 'supervised_user_circle',
                        'url': 'master-data-management/store-entity-data/' + child2Nav.child[v].id,
                        hidden: await this.prmsn.isAccessPerticularPermission('post_data_entity_' + child2Nav.child[v].id)
                      };

                      Child2NavArray.push(child2MenuItem);
                    }

                  }

                  // ============================
                  //   If Child 2 has DataEntity
                  // ============================

                  if (child2Nav.dataentity.length > 0) {
                    for (let w of child2Nav.dataentity) {
                      var deChild3MenuItem = {
                        'id': w.id,
                        'title': w.title,
                        'type': 'item',
                        'icon': 'supervised_user_circle',
                        'url': 'master-data-management/store-entity-data/' + w.id,
                        hidden: await this.prmsn.isAccessPerticularPermission('post_data_entity_' + w.id)
                      };
                      Child2NavArray.push(deChild3MenuItem);
                    }
                  }

                  // ===========================================
                  //   Push Child / DataEntity to Parent Child
                  // ===========================================

                  var collapsableChild3MenuItem = {
                    'id': child2Nav.id,
                    'title': child2Nav.name,
                    'type': 'collapsable',
                    'icon': 'supervised_user_circle',
                    'children': Child2NavArray,
                    hidden: await this.prmsn.isAccessPerticularPermission('post_data_entity_category_' + child2Nav.id)
                  };
                  Child1NavArray.push(collapsableChild3MenuItem);
                }
                console.log('Child 2 : ' + Child2NavArray);

              }

              // ============================
              //   If Child 1 has DataEntity
              // ============================

              if (child1Nav.dataentity.length > 0) {

                for (let y of child1Nav.dataentity) {
                  var dataentity2MenuItem = {
                    'id': y.id,
                    'title': y.title,
                    'type': 'item',
                    'icon': 'supervised_user_circle',
                    'url': 'master-data-management/store-entity-data/' + y.id,
                    hidden: await this.prmsn.isAccessPerticularPermission('post_data_entity_' + y.id)
                  };
                  Child1NavArray.push(dataentity2MenuItem);
                }

              }

              // =====================================
              //   Push Child / DataEntity to Parent
              // =====================================


              var Child3MenuItem = {
                'id': child1Nav.id,
                'title': child1Nav.name,
                'type': 'collapsable',
                'icon': 'supervised_user_circle',
                'children': Child1NavArray,
                hidden: await this.prmsn.isAccessPerticularPermission('post_data_entity_category_' + child1Nav.id)
              };
              parentNavArray.push(Child3MenuItem);

            }

          }

          // =========================
          //   If Data has DataEntity
          // =========================

          if (pNav.dataentity.length > 0) {
            for (let x of pNav.dataentity) {
              var deMenuItem = {
                'id': x.id,
                'title': x.title,
                'type': 'item',
                'icon': 'supervised_user_circle',
                'url': 'master-data-management/store-entity-data/' + x.id,
                hidden: await this.prmsn.isAccessPerticularPermission('post_data_entity_' + x.id)
              };
              parentNavArray.push(deMenuItem);
            }
          }

          // =======================================
          //   Push Child / DataEntity to Main Menu
          // ========================================

          var collapsableChild1MenuItem = {
            'id': pNav.id,
            'title': pNav.name,
            'type': 'collapsable',
            'icon': 'supervised_user_circle',
            'children': parentNavArray,
            hidden: await this.prmsn.isAccessPerticularPermission('post_data_entity_category_' + pNav.id)
          };

          this.deChildItem.push(collapsableChild1MenuItem);

        }

        // this.nav.push(menuItem);

      }
    });
  }

  getReportNavigation() {

    this.reportChildItem = [];

    this.apiService.get('report_configuration').subscribe(res => {
      var parentNavArray: FuseNavigation[] = [];

      for (let nav of res.data) {

        var reMenuItem = {
          'id': nav.id,
          'title': nav.display_label,
          'type': 'item',
          'icon': 'grade',
          'url': 'reports/report-details/' + nav.id
        };
        this.reportChildItem.push(reMenuItem);
      }


    });

  }

}

