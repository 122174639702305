import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { PermissionsService } from '../../service/permissions.service';
import { environment } from '../../../environments/environment';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'web-temp-dashboard',
  templateUrl: './temp-dashboard.component.html',
  styleUrls: ['./temp-dashboard.component.scss']
})
export class TempDashboardComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  region_id = '';
  allDashboardData: any = {};
  options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['rgb(44, 151, 222)', 'lightgray', 'green'],
    arcDelimiters: [30, 60],
    rangeLabel: ['0', '100'],
    needleStartValue: 0
  };
  allPackagesData = [];
  allZonesData = [];
  allWardsData = [];
  package_id = '';
  shift_id = '';
  dept_id = '';
  zone_id = '';
  allPoisData = [];
  allVehicleTypesData = [];
  modelData = {};
  all_employee_statistics = <any>{};
  complaint_status_count = <any>{};
  all_vehicle_statistics = <any>{};
  all_userCharges_data: any = {};

  maxDate = moment(new Date()).format('YYYY-MM-DD');
  workinghoursList = <any>{};
  deptList = <any>{};
  report_date = moment(new Date()).format('YYYY-MM-DD');
  report_date_minus_one = moment(new Date()).subtract(1, "days");

  public pieChartOptions = {
    responsive: true
  };
  public pieChartLabels = [
    'Present',
    'Absent'
  ];
  public pieChartData = [];
  public pieChartType = 'pie';
  public pieChartLegend = true;
  pieChartColor:any = [
    {
      backgroundColor: ['green',
        'red'
      ]
    }
  ]


  constructor(private getApiService: ApiService,
              private _formBuilder: FormBuilder,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              private appComponent: AppComponent,
              public prmsn: PermissionsService) {
  }

  ngOnInit(): void {
    this.getModelData('regionHierarchyUlb');
    this.getAllPoisData();
    this.getAllVehicleTypesData();
    this.getAllReportsData();
    this.getAllWorkHourList();
    if (this.prmsn.userAllDetails.data && (this.prmsn.userAllDetails.data[0].employee.ulb.name == 'Srinagar' || this.prmsn.userAllDetails.data[0].employee.ulb.name == 'Mehta  Associates  Indore')){
    this.getAttendanceData();
    }

    if (environment.environmentName != 'chennai'){
    this.getAllEmployeeStatistics();
    this.getAllVehicleStatistics();
    this.getUserChargesReport();
    }
    this.getAllDepartmentList();
  }

  getAllReportsData() {
    this.appComponent.showLoader();
    let report_date = moment(this.report_date).format('YYYY-MM-DD');
    let _url;
    if (environment.environmentName != 'swm-suite'){
      if (environment.environmentName == 'chennai'){
        _url = this.getApiService.getFromNewKpi('kpi_summary_dash_board?region_id=' + this.region_id + '&date=' + report_date + '&shift_id=' + this.shift_id);
       } else {
         _url = this.getApiService.getFromKpi('kpi_summary_dash_board?region_id=' + this.region_id + '&date=' + report_date + '&shift_id=' + this.shift_id);
       }
    _url.subscribe(res => {
      this.appComponent.hideLoader();
      this.allDashboardData = res.data;
    }, (error) => {
      this.appComponent.hideLoader();

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });  
    }
  }

  getAttendanceData() {
    this.appComponent.showLoader();
    let report_date = moment(this.report_date).format('YYYY-MM-DD');
   this.getApiService.get("attendance_region_report?from_date=" +report_date+ "&region_id=" + this.region_id + '&shift_id=' + this.shift_id + '&perpage=1000').subscribe(res => {
      this.appComponent.hideLoader();
      // this.attendanceData = res.data;
     let _presents = 0;
     let _absents = 0;
     let _totals = 0;
     for (let data of res.data){
       _presents += data.zone_attandence.present;
       _absents += data.zone_attandence.absent;
     }
     this.pieChartData = [_presents, _absents];
    }, (error) => {
      this.appComponent.hideLoader();

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllWorkHourList() {
    this.getApiService.get("working_hours_shift").subscribe(res => {
      this.workinghoursList = res;
      // this.workinghoursList.data.sort(function(a, b) {
      //   var nameA = a.shift_name; // ignore upper and lowercase
      //   var nameB = b.shift_name; // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }
      //   // names must be equal
      //   return 0;
      // });
      let shift =  this.workinghoursList.data.find((val) => val.shift_name == "Full Day");
      if (shift) {
        this.shift_id = shift.id;
      }
    }, (error) => {
    });
  }

  getDashboardDataForWardKPIData(_kpi_id, _ward_id, _index, _poi, _vehicle_type) {
    let report_date = moment(this.report_date).format('YYYY-MM-DD');

    let _region_id = '';
    if (_ward_id == '') {
      _region_id = this.region_id;
    } else {
      _region_id = _ward_id;
    }
    this.appComponent.showLoader();
    this.getApiService.getFromNewKpi('single_kpi_value/' + _kpi_id + '?region_id=' + _region_id + '&poi_id=' + _poi + '&vehicle_type_id=' + _vehicle_type
      + '&date=' + report_date + '&shift_id=' + this.shift_id).subscribe(res => {
      this.appComponent.hideLoader();
      res.data.vehicle_type = _vehicle_type;
      res.data.poi = _poi;
      res.data.ward = _ward_id;
      this.allDashboardData[_index] = res.data;
    }, (error) => {
      this.appComponent.hideLoader();

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getModelData(field_name) {
    let model_id = this.prmsn.userAllDetails.data && (this.prmsn.userAllDetails.data[0].employee.ulb.name == 'Srinagar' || this.prmsn.userAllDetails.data[0].employee.ulb.name == 'Mehta Associates Indore' )? 2 : 26;
    this.getApiService.get('model_data/' + model_id).subscribe(res => {
      this.modelData[field_name] = JSON.parse(JSON.stringify([res.data]));
      this.modelData[field_name][0]['field_index'] = 0;
      this.modelData['regionHierarchyUlb'][0].ngmodel = '';

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  modelChangeforSrinagar(_value, _model, field_name, _index, model_id) {

    let modelData = JSON.parse(JSON.stringify(this.modelData[field_name]));

    let i = modelData.length; //initialize counter to array length

    while (i--) //decrement counter
    {
      if ((this.modelData[field_name][i] && this.modelData[field_name][i].field_index) > _index) {
        this.modelData[field_name].splice(i, 1);
      }

    }
    
    if(_value == ''){
      this.region_id = _model.value ? _model.value : '';
    } else {
      this.region_id = _value.value;
  
      if (_value.field_data.length <= 0) {
  
      }
      else {
        let index = this.modelData[field_name].findIndex(item => item.field_label == _value.field_label);
        if (index == -1) {
          _value.field_index = _index + 1;
          _value.ngmodel = '';
          this.modelData[field_name].push(_value);
        }
      }
    }


    this.getAttendanceData();
    this.getAllEmployeeStatistics();
    this.getAllVehicleStatistics();
    this.getUserChargesReport();
  }


  modelChange(_value, _model, field_name, _index, model_id) {
    console.log(_value);
    console.log(_model);
    this.allZonesData = [];
    this.allWardsData = [];
    this.zone_id = '';
    if (_value == '') {
      this.region_id = '';
    } else {

      let modelData = JSON.parse(JSON.stringify(this.modelData[field_name]));

      let i = modelData.length; //initialize counter to array length

      while (i--) //decrement counter
      {
        if ((this.modelData[field_name][i] && this.modelData[field_name][i].field_index) > _index) {
          this.modelData[field_name].splice(i, 1);
        }

      }

      this.region_id = _value.value;

      if (_value.field_data.length <= 0) {

      }
      else {
        let index = this.modelData[field_name].findIndex(item => item.field_label == _value.field_label);
        if (index == -1) {
          _value.field_index = _index + 1;
          _value.ngmodel = '';
          this.modelData[field_name].push(_value);
        }
      }

      if (_value.value != '') {
        this.getAllZonesData();
      }
    }
    this.getAllReportsData();


  }

  getAllZonesData() {
    this.getApiService.get('child_regions/' + this.region_id).subscribe(res => {

      this.allZonesData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllWardsData() {
    this.getApiService.get('child_regions/' + this.region_id).subscribe(res => {

      this.allWardsData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllPoisData() {
    this.getApiService.get('pois?poi_type_id=5').subscribe(res => {

      this.allPoisData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllVehicleTypesData() {
    this.getApiService.get('vehicle_types').subscribe(res => {

      this.allVehicleTypesData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  onZoneChange() {
    this.allWardsData = [];
    this.region_id = this.zone_id;
    if (this.zone_id != '') {
      this.getAllWardsData();
    }
    this.getAllReportsData();
  }

  onShiftChange() {
    this.getAllReportsData();
    this.getAttendanceData();
  }

  onDeptChange() {
    this.getAllEmployeeStatistics();
    this.getAllVehicleStatistics();
  }

  onDatechange(){
    this.report_date_minus_one = moment(new Date(this.report_date)).subtract(1, "days");
    this.getAllReportsData();
    this.getAttendanceData();
    this.getAllEmployeeStatistics();
    this.getAllVehicleStatistics();
    this.getUserChargesReport();
  }

  onWardChange(_kpi_id, _ward_id, _index) {
    this.getDashboardDataForWardKPIData(_kpi_id, _ward_id, _index, '', '');
  }

  onPoiChange(_kpi_id, _poi_id, _index) {
    this.getDashboardDataForWardKPIData(_kpi_id, '', _index, _poi_id, '');
  }

  onVehicleTypeChange(_kpi_id, _vehicle_type_id, _index) {
    this.getDashboardDataForWardKPIData(_kpi_id, '', _index, '', _vehicle_type_id);
  }


  getAllEmployeeStatistics() {
    let report_date = moment(this.report_date).format('YYYY-MM-DD');
    this.getApiService.get('employee_statistics?from_date=' +report_date+ "&region_id=" + this.region_id + '&dept_id=' + this.dept_id).subscribe(res => {

      this.all_employee_statistics = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });

    this.getApiService.get('complaint_status_count_report?from_date=' +report_date+ '&region_id=' + this.region_id).subscribe(res => {

      this.complaint_status_count = res.data;
      console.log(this.complaint_status_count);

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });


  }

  getAllVehicleStatistics() {
    let report_date = moment(this.report_date).format('YYYY-MM-DD');
    this.getApiService.get('vehicle_statistics?from_date=' +report_date+ "&region_id=" + this.region_id + '&dept_id=' + this.dept_id).subscribe(res => {

      this.all_vehicle_statistics = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getUserChargesReport() {
    let report_date = moment(this.report_date).format('YYYY-MM-DD');
    this.getApiService.get('property_count_report?start_date=' +report_date+ "&region_id=" + this.region_id + '&service_type_id=2').subscribe(res => {

      this.all_userCharges_data = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllDepartmentList() {
    this.getApiService.get("department").subscribe(res => {
      this.deptList = res;
    }, (error) => {
      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

}