import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../service/permissions.service';
import { Observable } from 'rxjs';
import {NavigationService} from '../navigation/navigation';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router, private activatedRoute: ActivatedRoute, private prmsn: PermissionsService, private _navigationService : NavigationService
  ) { }

  canActivate(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let routerLink = ''

    status = localStorage.getItem('loginStatus');


    if (status == "true") {
      let routeArray = router.routeConfig.path.split('/')
      if (router.routeConfig.path.includes(':')) {
        routeArray.forEach((element, index) => {
          if (!element.includes(':')) {
            routerLink += element + (index != routeArray.length - 2 ? '/' : '')
          }
        });
      }
      else {
        routerLink = router.routeConfig.path
      }

      if (this.prmsn.permission_load) {
        if (this.prmsn.isAccessPage(routerLink)) {
          return true;
        } else {
          this.router.navigate(['/login']);
        }
      } else {
        return this.prmsn.getUserDetailsSrvr().then((per) => {
          this._navigationService.setNavConfig();
          if (this.prmsn.isAccessPage(routerLink)) {
            return true;
          } else {
            if(router.queryParams && router.queryParams.password && router.queryParams.username){
              this.router.navigate(['/login'], { queryParams: { returnUrl: router['_routerState'].url } });
            } else {
              this.router.navigate(['/login']);
            }
            return false;
          }
        },
          (err) => {
            console.log('Api Response Rejected');
            return false;
          });
      }
    }
    else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;

    }
    // return true;
  }

}
