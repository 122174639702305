<h1 mat-dialog-title>
    {{title}}
  </h1>
   
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  
  
  <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="flex-wrap">
      <mat-form-field appearance="outline" style="width: 300px;padding-top: 14px; font-size: initial;" class="pr-8">
        <mat-label style="font-size: 16px;">ULB Name</mat-label>
        <mat-select style="font-size: 16px;" matInput [(ngModel)] = "ulb_id">
          <ng-container>
            <mat-option *ngFor="let option of ulbList" [value]="option.id">
              {{option.name}}
            </mat-option>
          </ng-container>
        </mat-select>
    </mat-form-field>

    </div>
  </div>
  
  
   
  <div style="margin-top: 15px;text-align: right;">
    <button mat-raised-button color="warn" style="margin-right: 10px;" (click)="onDismiss()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Submit</button>
  </div>
  
  <!-- <div mat-dialog-actions *ngIf="is_alert">
    <button mat-raised-button color="primary" (click)="onDismiss()">Ok</button>
  </div> -->
  