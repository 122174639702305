import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {SlugifyPipe} from '../pipes/slugify.pipe';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  permission_load = false;
  userPermissionsArr: any = [];
  userAllDetails: any = [];

  permissionsobj = {
    'division-management': 'division_management',
    'district-management': 'district_management',
    'ulb-type': 'ulb_type',
    'state-management': 'state_management',
    'ulb-management': 'ulb_management',
    'scheme-category': 'scheme_category',
    'manage-scheme': 'manage_scheme',
    'state-profiling': 'state_profiling',
    'scheme-management': 'scheme_management',
    'system-profile': 'system_profile'
  };

  configObj = {
    otpTimer : 60,
    passwordReset : {
      email : null,
      adminReset : null
    }
  };



  constructor(private getApiService: ApiService, private slugifyPipe: SlugifyPipe) { }


  setPermissions(_prmsn) {
    this.userPermissionsArr = _prmsn;
  }

  getUrlPermission(_url) {
    return this.permissionsobj[_url];
  }

  isAccessPage(_page) {
    try {
      // _page = _page.split('/');
      // _page = _page[_page.length - 1];
      // _page = _page[2];

      let permission = this.slugifyPipe.transform(_page);

      // let permission = this.getUrlPermission(_page);

      if (this.userPermissionsArr.includes(permission) || this.userPermissionsArr.includes('do_everything')) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('Error Exception UserService isAccessPage ' + e);
    }
  }

  checkForGroupAndPermission(_group, _permission) {
    try {

      _group = _group ? this.slugifyPipe.transform(_group) : this.slugifyPipe.transform(_permission);
      _permission = this.slugifyPipe.transform(_permission);

      if ((this.userPermissionsArr.includes(_group) && this.userPermissionsArr.includes(_permission)) || this.userPermissionsArr.includes('do_everything')) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('Error Exception UserService isAccessPage ' + e);
    }
  }


  isAccessPerticularPermission(_permission) {
    try {

      status = localStorage.getItem('loginStatus');


      if (status == "true") {
        if (this.permission_load) {
          if (this.isAccessPage(_permission)) {
            return false;
          } else {
            return true;
          }
        } else {
          return this.getUserDetailsSrvr().then((per) => {
              if (this.isAccessPage(_permission)) {
                return false;
              } else {
                return true;
              }
            },
            (err) => {
              console.log('Api Response Rejected');
              return true;
            });
        }
      } else {
        return true;
      }

      // if (this.userPermissionsArr.includes('do_everything')   || this.userPermissionsArr.includes(_permission)) {
      //   return true;
      // } else {
      //   return false;
      // }
    } catch (e) {
      console.log('Error Exception UserService isAccessPage ' + e);
    }
  }

  isAccessGroupPermission(_group, _permission) {
    try {

      status = localStorage.getItem('loginStatus');


      if (status == "true") {
        if (this.permission_load) {
          if (this.checkForGroupAndPermission(_group, _permission)) {
            return false;
          } else {
            return true;
          }
        } else {
          return this.getUserDetailsSrvr().then((per) => {
              if (this.checkForGroupAndPermission(_group, _permission)) {
                return false;
              } else {
                return true;
              }
            },
            (err) => {
              console.log('Api Response Rejected');
              return true;
            });
        }
      } else {
        return true;
      }

      // if (this.userPermissionsArr.includes('do_everything')   || this.userPermissionsArr.includes(_permission)) {
      //   return true;
      // } else {
      //   return false;
      // }
    } catch (e) {
      console.log('Error Exception UserService isAccessPage ' + e);
    }
  }

  hasAnyRights() {
    let key = '';
    try {

      this.userPermissionsArr.some((val) => {
        key = Object.keys(this.permissionsobj).find(key => this.permissionsobj[key] === val);
        if(key != null || key != undefined){
          return key;
        }
      });
      return key;

    } catch (e) {
      console.log('Error Exception UserService isAccessPage ' + e);
    }
  }

  setEnvLatLng(data){
    if(data && data.employee && data.employee.ulb && data.employee.ulb.lat) {
      environment.c_lat = data.employee.ulb.lat;
      environment.c_lng = data.employee.ulb.lng;
    }
}


  getUserDetailsSrvr() {
    let promise = new Promise((resolve, reject) => {
      this.getApiService.get('user_detail')
        .toPromise()
        .then(
          res => {
            console.log(res);
            this.userAllDetails = res;
            if (res.permissions) {
              this.userPermissionsArr = res.permissions;
              this.permission_load = true;
            }
            this.setEnvLatLng(res.data && res.data[0]);
            resolve(res);
          },
          msg => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}



