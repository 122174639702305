import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatPaginatorGotoComponent} from '../app/main/mat-paginator-goto/mat-paginator-goto.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  declarations : [
    MatPaginatorGotoComponent
  ],
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        MatFormFieldModule,
        FlexLayoutModule,

        FuseDirectivesModule,
        FusePipesModule,
      MatPaginatorModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        MatFormFieldModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
      MatPaginatorGotoComponent,
      MatPaginatorModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule
    ]
})
export class FuseSharedModule
{
}
