<div *ngIf="data.type == 'imageDialog'" mat-dialog-content>

  <img style="max-width:560px; max-height: 400px"
    src="https://swm-api.acceldash.com/uploads/documents/1563788528989.jpeg">
  <h4 style="text-align: center;">{{descp}}</h4>

</div>

<div *ngIf="data.type == 'imageDialog_dynamic'" class="img-viewer" style="text-align: center;">
  <div class="close_dialog" (click)="close()"><mat-icon>close</mat-icon></div>
  <div class="zoomImage" style="max-height: 80vh;overflow: hidden;overflow-Y: auto;">
    <img src="{{data.imageData.actual_path}}" alt="">
  </div>
</div>

<div *ngIf="data.type == 'slide_image'" class="img-viewer" style="text-align: end;width: 450px;">
  <div class="close_dialog" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
  <mat-carousel timings="250ms ease-in"  [autoplay]="true" interval="5000" color="accent" maxWidth="auto" proportion="25"
	slides="5" [loop]="true" [hideArrows]="data.imageData.length > 1 ?  false : true" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="false"
	orientation="ltr">

	<mat-carousel-slide #matCarouselSlide   *ngFor="let slide of data.imageData; let i = index"
  [image]="slide.actual_path"
		overlayColor="#00000040" [hideOverlay]="false">
    <mat-icon  matTooltip="Download" class="download_icon" (click) = "download(slide)">get_app</mat-icon>
    {{slide?.created_at}}
    <a  class="location" *ngIf = "slide?.location && slide?.location?.x && slide?.location?.y" href="http://maps.google.com/maps?q=loc:{{slide?.location?.y}},{{slide?.location?.x}}" target="_blank">Location</a>
	</mat-carousel-slide>
  </mat-carousel>
</div>



<div *ngIf="data.type == 'successDialog'" style="width:300px">
  <h2 mat-dialog-title>Success!</h2>
  <div mat-dialog-content style="padding-bottom: 10px; color: green;">{{successMsg}}</div>
  <div mat-dialog-actions class="mb-4">
    <span>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="close()"
      >
        Ok
      </button>
    </span>
  </div>
</div>

<div *ngIf="data.type == 'errorDialog'" style="width:300px">
  <h2 mat-dialog-title>Error!</h2>
  <div mat-dialog-content style="padding-bottom: 10px; color: red;">{{errorMsg}}</div>
  <div mat-dialog-actions class="mb-4">
    <span>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="close()"
      >
        Ok
      </button>
    </span>
  </div>
</div>

<!-- <div *ngIf="data.type == 'imageDialog_dynamic'" class="imageDialog_dynamic" style="max-height: 500px; width: 560px;">
  <div class="close_dialog" (click)="close()"><mat-icon>close</mat-icon></div>
  <mat-carousel
    timings="250ms ease-in"
    [autoplay]="false"
    interval="5000"
    color="accent"
    proportion="50"
    [loop]="false"
    [hideArrows]="false"
    [hideIndicators]="false"
    [useKeyboard]="false"
    [useMouseWheel]="false"
    orientation="ltr"
    slideHeight="100%"
    maintainAspectRatio="false"
    style="margin: 5px auto;border: 1ps solid; line-height: 19px !important;">
    <mat-carousel-slide
      #matCarouselSlide
      *ngFor="let slide of data.imageData; let i = index"
      [image]="slide.actual_path ? slide.actual_path: slide.image_path"
      overlayColor="#00000040"
      [hideOverlay]="true">
      <audio class="cmnt-audio" controls *ngIf="slide.name == 'audio.aac'">
        <source [src]="slide.actual_path" >
      </audio>
    </mat-carousel-slide>
  </mat-carousel>
</div> -->

<div *ngIf="data.type == 'qrDialog'" mat-dialog-content fxLayout="column">

  <span style="text-align: center; color: #02ad08;padding-bottom: 10px;
  border-bottom: 1px solid #ddd;">Your feedback submited successfully</span>
  <h4 style="text-align: center; font-size: 20px;font-weight: bold;">Would you be willing for feedback call?</h4>

  <form name="form" [formGroup]="form" (ngSubmit)="Save()" fxLayout="column" fxLayoutAlign="center" fxFlex="100">

    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
      <mat-label>Mobile No.</mat-label>
      <input matInput formControlName="mobno">
      <mat-error>Mobile Number is required!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
      <mat-error>Name is required!</mat-error>
    </mat-form-field>

    <div class="ng-button-row">
      <button mat-raised-button color="accent" type="submit">Save</button>
      <button mat-raised-button color="warn" type="button" (click)="close()">Close</button>
    </div>

  </form>

</div>