<h1 mat-dialog-title>
    {{title}}
  </h1>
   
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  
  
  <div fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row" style="width: 300px;">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="flex-wrap">
        <div class="flex-100 select-multy pr-8" style="margin-top:0px;">
            <h4><b>ULB Name</b></h4>
            <angular2-multiselect [data]="ulbList" [(ngModel)]="ulb_id"
            [ngModelOptions]="{standalone: true}" [settings]="{singleSelection: false,
                        text:'Select',
                        badgeShowLimit: 2,
                        enableSearchFilter: true,
                        labelKey:'name',
                        primaryKey:'id'}">
            <c-badge>
              <ng-template let-item="item">
                <label>{{item.name}}</label>
              </ng-template>
            </c-badge>
          </angular2-multiselect>
        </div>

      <!-- <mat-form-field appearance="outline" style="width: 300px;padding-top: 14px; font-size: initial;" class="pr-8">
        <mat-label style="font-size: 16px;">ULB Name</mat-label>
        <mat-select style="font-size: 16px;" matInput [(ngModel)] = "ulb_id">
          <ng-container>
            <mat-option *ngFor="let option of ulbList" [value]="option.id">
              {{option.name}}
            </mat-option>
          </ng-container>
        </mat-select>
    </mat-form-field> -->

    </div>
  </div>
  
  
   
  <div style="margin-top: 15px;text-align: right;">
    <button mat-raised-button color="warn" style="margin-right: 10px;" (click)="onDismiss()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Submit</button>
  </div>
  
  <!-- <div mat-dialog-actions *ngIf="is_alert">
    <button mat-raised-button color="primary" (click)="onDismiss()">Ok</button>
  </div> -->
  