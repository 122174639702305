<div class="page-layout blank p-24" fusePerfectScrollbar>

    <h2>{{'SAMPLE.HELLO' | translate}}</h2>

  <gridster [options]="options">
    <gridster-item [item]="item" *ngFor="let item of dashboard">
      <div class="button-holder">
        <label >{{item.label}}</label>
        <button mat-mini-fab  (mousedown)="removeItem($event, item)"
                (touchstart)="removeItem($event, item)">
          <mat-icon matTooltip="Delete">delete</mat-icon>
        </button>
      </div>

    </gridster-item>
  </gridster>

</div>

