import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { FormGroup, AbstractControl } from '@angular/forms';
import {environment} from '../../environments/environment';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected baseUrl: string = environment.apiUrl;
  public imageUrl: string = environment.imageUrl;
  public kpiUrl: string = 'https://chennai-kpi-api.acceldash.com/api/v1/';
  public payTimeUrl: string = 'http://paytime.acceldash.com:3000/iclock/';
  public kpiDevUrl: string = 'https://chennai-kpi-api-dev.acceldash.com/api/v1/';


  // public imageUrl: string = 'http://192.168.1.150:3300';
  //
  //
  // protected baseUrl: string = 'http://192.168.1.150:3300/api/v1/';



  // private headers: HttpHeaders;

  // private options: HttpParams;

  httpHeaders = new HttpHeaders()
    // .set('Authorization', 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImlhdCI6MTU1OTk3NjYzMH0.jyozSa3AdHV_ezESxYcGwYrsnhZwTWdf_gx1IdWdpmE')
    .set('Content-Type', 'application/json')
    .set("Accept", "application/json");


  constructor(private http: HttpClient) {
    // this.headers = new HttpHeaders();
    // this.headers.set("Content-Type", "application/json");
    // this.headers.set("Accept", "application/json");
    // this.options = new HttpParams().set('headers', this.headers);
  }

  public get(relativeUrl: string): Observable<any> {
    return this.http.get(this.baseUrl + relativeUrl, { headers: this.httpHeaders, responseType: 'json' });

  }

  public getGoogleLocations(_searchKey): Observable<any> {
    return this.http.get("https://maps.googleapis.com/maps/api/place/queryautocomplete/json?key=AIzaSyATpKfqvgp2iHysKp6A0C3xYtS6JhtXW7g&input=" + _searchKey);

  }

  public post(relativeUrl: string, data: any): Observable<any> {
    return this.http.post(this.baseUrl + relativeUrl, data, { headers: this.httpHeaders, responseType: 'json' });

  }

  public postFromData(relativeUrl: string, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEsImlhdCI6MTU2MTAyODEyNH0.dKCED5xUnovmptIiCMnLBeneX3TyMuu8m3Ptm6EVyQQ'
      })
    };
    return this.http.post(this.baseUrl + relativeUrl, data, options);

  }

  public putFormData(relativeUrl: string, data: any): Observable<any> {
    return this.http.put(this.baseUrl + relativeUrl, data);
  }

  public put(relativeUrl: string, data: any): Observable<any> {
    return this.http.put(this.baseUrl + relativeUrl, data, { headers: this.httpHeaders, responseType: 'json' });
  }

  public delete(relativeUrl: string): Observable<any> {
    return this.http.delete(this.baseUrl + relativeUrl, { headers: this.httpHeaders, responseType: 'json' });
  }

  public getFromKpi(relativeUrl: string): Observable<any> {
    return this.http.get(this.kpiUrl + relativeUrl, { headers: this.httpHeaders, responseType: 'json' });

  }


  public getFromNewKpi(relativeUrl: string): Observable<any> {
    return this.http.get(this.kpiDevUrl + relativeUrl, { headers: this.httpHeaders, responseType: 'json' });

  }

  public getBlobFileFromServer(relativeUrl: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type','*');
    return this.http.get(relativeUrl, { headers : headers,  responseType: 'blob' });
  }

  public postKpiFormData(relativeUrl: string, data: any): Observable<any> {
    return this.http.post(this.kpiUrl + relativeUrl, data).pipe(retry(3))
  }

  public postNewKpiFormData(relativeUrl: string, data: any): Observable<any> {
    return this.http.post(this.kpiDevUrl + relativeUrl, data).pipe(retry(3))
  }

  public postNewFormData(relativeUrl: string, data: any): Observable<any> {
    return this.http.post(this.baseUrl + relativeUrl, data).pipe(retry(3))
  }

  public postKpiData(relativeUrl: string, data: any): Observable<any> {
    return this.http.post(this.kpiUrl + relativeUrl, data, { headers: this.httpHeaders, responseType: 'json' });

  }

  public putKpiData(relativeUrl: string, data: any): Observable<any> {
    return this.http.put(this.kpiUrl + relativeUrl, data, { headers: this.httpHeaders, responseType: 'json' });

  }

  public getpaytime(relativeUrl: string): Observable<any> {
    return this.http.get(this.payTimeUrl + relativeUrl, { headers: this.httpHeaders, responseType: 'json' });

  }

  public resetForm(formGroup: FormGroup) {
    let control: AbstractControl = null;
    formGroup.reset();
    formGroup.markAsUntouched();
    Object.keys(formGroup.controls).forEach((name) => {
      control = formGroup.controls[name];
      control.setErrors(null);
    });
    formGroup.setErrors({ 'invalid': true });
  }

  getPolyCenter(arr) {
    if (arr.length && arr[0].length && Array.isArray(arr[0][0])) {
      arr = arr[0];
    }
    const x = arr.map(function(a) {
      return a[0];
    });
    const y = arr.map(function(a) {
      return a[1];
    });
    const minX = Math.min.apply(null, x);
    const maxX = Math.max.apply(null, x);
    const minY = Math.min.apply(null, y);
    const maxY = Math.max.apply(null, y);
    return [(minY + maxY) / 2, (minX + maxX) / 2];
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }


}
