<div class="page-layout carded fullwidth">

  <!-- CENTER -->
  <div class="fuse-widget-front center dashboard-container">

    <!-- CONTENT HEADER -->
    <div class="p-12" fxLayout="row" fxLayoutAlign="start center">

      <h2 class="m-0 dashoard-header" style="min-width: 100%;"><span>Dashboard</span>
        <div style="float: right;">
          <mat-form-field appearance="outline" class="pr-8 dash_header dash_date">
            <mat-label>Date</mat-label>
            <input matInput [owlDateTime]="dt3" [max]="maxDate" [owlDateTimeTrigger]="dt3" name="report_date"
                   [(ngModel)]="report_date"  readonly (dateTimeInput)="onDatechange()">
            <owl-date-time [pickerType]="'calendar'" [pickerMode]="'dialog'" #dt3></owl-date-time>
            <mat-error>Date is required!</mat-error>
          </mat-form-field>


          <mat-form-field appearance="outline" class="pr-8 dash_header dash_drop">
            <mat-label>Zone</mat-label>
            <mat-select matInput name="zone" [(ngModel)]="zone_id" (selectionChange)="onZoneChange()">
              <mat-option value="">Select</mat-option>
              <mat-option *ngFor="let group of allZonesData" [value]="group.id">
                {{group.region_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field appearance="outline" class="pr-8 dash_header dash_drop">
            <mat-label>Shift</mat-label>
            <mat-select matInput name="zone" [(ngModel)]="shift_id" (selectionChange)="onShiftChange()">
              <ng-container>
                <mat-option value="">Select</mat-option>
                <mat-option *ngFor="let option of workinghoursList.data" [value]="option.id">
                  {{option.shift_name}}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error>Shift is required!</mat-error>
          </mat-form-field>


          <!--<mat-form-field appearance="outline" class="pr-8 flex-25">-->
          <!--<mat-label>Ward</mat-label>-->
          <!--<mat-select matInput  name="ward" [(ngModel)]="ward_id" (selectionChange)="onWardChange()">-->
          <!--<mat-option value="">Select</mat-option>-->
          <!--<mat-option *ngFor="let group of allWardsData" [value]="group.id">-->
          <!--{{group.name}}-->
          <!--</mat-option>-->
          <!--</mat-select>-->
          <!--</mat-form-field>-->
        </div>
      </h2>
      <!--<select class="mr-5 select-dropdowns main-dropdown">-->
      <!--<option>Package 5</option>-->
      <!--<option>Package 2</option>-->
      <!--</select>-->
      <!--<select class="pr-8 pull-right main-dropdown">-->
      <!--<option>All</option>-->
      <!--<option>Zone 9</option>-->
      <!--<option>Zone 10</option>-->
      <!--<option>Zone 13</option>-->
      <!--</select>-->
    </div>

    <!-- / CONTENT HEADER -->

    <!-- CONTENT CARD -->
    <div class="flex-wrap">
      <div class="flex-50 mb-12" fxLayout="column" fxLayoutAlign="start">
        <div class="mat-elevation-z8 mr-8 bg-white">
          <h2 class="sub-dashoard-header lite-grn bdr-btm">Primary Collection</h2>
          <div class="tab_wrap">
            <table class="p-12 dashboard-table-wrapper pl-24">
              <tr> <th></th>
                <th></th>
                <th>Achieved</th>
                <th>Target</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/group.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[0].kpi_id, date: report_date | date : 'yyyy-MM-dd' }" >{{allDashboardData.length && allDashboardData[0].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[0].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[0].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[0].kpi_percentage| number : '1.1-1') ? (allDashboardData[0].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[0].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <mat-form-field appearance="outline" *ngIf="allDashboardData[0].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[0].ward" (selectionChange)="onWardChange(allDashboardData[0].id, $event.value, 0)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[0].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[0].poi" (selectionChange)="onPoiChange(allDashboardData[0].id, $event.value, 0)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[0].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[0].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[0].id, $event.value, 0)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/garbage-truck.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[1].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[1].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[1].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[1].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[1].kpi_percentage| number : '1.1-1') ? (allDashboardData[1].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[1].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <mat-form-field appearance="outline" *ngIf="allDashboardData[1].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[1].ward" (selectionChange)="onWardChange(allDashboardData[1].id, $event.value, 1)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[1].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[1].poi" (selectionChange)="onPoiChange(allDashboardData[1].id, $event.value, 1)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[1].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[1].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[1].id, $event.value, 1)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>


              <!-- <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/garbage-truck(1).png"/>
                </td>
                <td>
                  <small [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[0].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[2].name}}</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[2].actual_value}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[2].min_required}}</span>
                </td>

                <td>

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[2].kpi_percentage| number : '1.1-1') ? (allDashboardData[2].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[2].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <mat-form-field appearance="outline" *ngIf="allDashboardData[2].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[2].ward" (selectionChange)="onWardChange(allDashboardData[2].id, $event.value, 2)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[2].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[2].poi" (selectionChange)="onPoiChange(allDashboardData[2].id, $event.value, 2)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[2].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[2].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[2].id, $event.value, 2)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr> -->

              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/trash.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[3].kpi_id, date: report_date_minus_one | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[3].name}}</a> (in Kgs)</small>
                  <span class="yesterday">(Yesterday)</span>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[3].actual_value}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[3].min_required}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[3].kpi_percentage| number : '1.1-1') ? (allDashboardData[3].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[3].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <mat-form-field appearance="outline" *ngIf="allDashboardData[3].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[3].ward" (selectionChange)="onWardChange(allDashboardData[3].id, $event.value, 3)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[3].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[3].poi" (selectionChange)="onPoiChange(allDashboardData[3].id, $event.value, 3)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[3].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[3].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[3].id, $event.value, 3)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/garbage.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[4].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[4].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[4].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[4].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[4].kpi_percentage| number : '1.1-1') ? (allDashboardData[4].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[4].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <mat-form-field appearance="outline" *ngIf="allDashboardData[4].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[4].ward" (selectionChange)="onWardChange(allDashboardData[4].id, $event.value, 4)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[4].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[4].poi" (selectionChange)="onPoiChange(allDashboardData[4].id, $event.value, 4)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[4].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[4].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[4].id, $event.value, 4)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="mat-elevation-z8 mt-12 mr-8 bg-white">
          <h2 class="sub-dashoard-header lite-orng bdr-btm">Street Sweeping & Collection</h2>
          <div class="tab_wrap">
            <table class="p-12 dashboard-table-wrapper pl-24">
              <tr> <th></th>
                <th></th>
                <th>Achieved</th>
                <th>Target</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/clock.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[5].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[5].name}}</a> (in Kms)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[5].actual_value}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[5].min_required}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[5].kpi_percentage| number : '1.1-1') ? (allDashboardData[5].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[5].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <mat-form-field appearance="outline" *ngIf="allDashboardData[5].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[5].ward" (selectionChange)="onWardChange(allDashboardData[5].id, $event.value, 5)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[5].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[5].poi" (selectionChange)="onPoiChange(allDashboardData[5].id, $event.value, 5)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[5].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[5].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[5].id, $event.value, 5)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/group.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[6].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[6].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[6].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[6].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[6].kpi_percentage| number : '1.1-1') ? (allDashboardData[6].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[6].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <mat-form-field appearance="outline" *ngIf="allDashboardData[6].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[6].ward" (selectionChange)="onWardChange(allDashboardData[6].id, $event.value, 6)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[6].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[6].poi" (selectionChange)="onPoiChange(allDashboardData[6].id, $event.value, 6)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[6].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[6].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[6].id, $event.value, 6)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/garbage-truck(2).png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[7].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[7].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[7].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[7].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[7].kpi_percentage| number : '1.1-1') ? (allDashboardData[7].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[7].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <mat-form-field appearance="outline" *ngIf="allDashboardData[7].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[7].ward" (selectionChange)="onWardChange(allDashboardData[7].id, $event.value, 7)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[7].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[7].poi" (selectionChange)="onPoiChange(allDashboardData[7].id, $event.value, 7)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[7].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[7].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[7].id, $event.value, 7)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="flex-50 mb-12" fxLayout="column" fxLayoutAlign="start">
        <div class="mat-elevation-z8 mr-8 bg-white">
          <h2 class="sub-dashoard-header lite-blue bdr-btm">Secondary Collection & Transportation</h2>
          <div class="tab_wrap">
            <table class="p-12 dashboard-table-wrapper pl-24">
              <tr> <th></th>
                <th></th>
                <th>Achieved</th>
                <th>Target</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/trash.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[8].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[8].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[8].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[8].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[8].kpi_percentage| number : '1.1-1') ? (allDashboardData[8].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[8].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <!-- <mat-form-field appearance="outline" *ngIf="allDashboardData[8].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[8].ward" (selectionChange)="onWardChange(allDashboardData[8].id, $event.value, 8)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[8].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[8].poi" (selectionChange)="onPoiChange(allDashboardData[8].id, $event.value, 8)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[8].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[8].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[8].id, $event.value, 8)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/garbage-truck(1).png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[9].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[9].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[9].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[9].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[9].kpi_percentage| number : '1.1-1') ? (allDashboardData[9].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[9].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <mat-form-field appearance="outline" *ngIf="allDashboardData[9].filter_type == 'ward' && zone_id != ''"  class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[9].ward" (selectionChange)="onWardChange(allDashboardData[9].id, $event.value, 9)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[9].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[9].poi" (selectionChange)="onPoiChange(allDashboardData[9].id, $event.value, 9)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- <mat-form-field appearance="outline" *ngIf="allDashboardData[9].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[9].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[9].id, $event.value, 9)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
                </td>
              </tr>

              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/group.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[10].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[10].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[10].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[10].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[10].kpi_percentage| number : '1.1-1') ? (allDashboardData[10].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[10].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <!-- <mat-form-field appearance="outline" *ngIf="allDashboardData[10].filter_type == 'ward' && zone_id != ''" class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[10].ward" (selectionChange)="onWardChange(allDashboardData[10].id, $event.value, 10)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[10].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[10].poi" (selectionChange)="onPoiChange(allDashboardData[10].id, $event.value, 10)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[10].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[10].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[10].id, $event.value, 10)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>

              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/motorway.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[11].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[11].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[11].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[11].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[11].kpi_percentage| number : '1.1-1') ? (allDashboardData[11].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[11].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <!-- <mat-form-field appearance="outline" *ngIf="allDashboardData[11].filter_type == 'ward' && zone_id != ''" class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[11].ward" (selectionChange)="onWardChange(allDashboardData[11].id, $event.value, 11)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[11].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[11].poi" (selectionChange)="onPoiChange(allDashboardData[11].id, $event.value, 11)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[11].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[11].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[11].id, $event.value, 11)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="mat-elevation-z8 mt-12 mr-8 bg-white">
          <h2 class="sub-dashoard-header lite-brwn bdr-btm">MIS</h2>
          <div class="tab_wrap">
            <table class="p-12 dashboard-table-wrapper pl-24">
              <tr> <th></th>
                <th></th>
                <th>Achieved</th>
                <th>Target</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/group.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[12].kpi_id, date: report_date | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[12].name}}</a> (in No’s)</small>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[12].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[12].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[12].kpi_percentage| number : '1.1-1') ? (allDashboardData[12].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[12].kpi_percentage| number : '1.1-1'}}%</span>
                </td>
                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <!-- <mat-form-field appearance="outline" *ngIf="allDashboardData[12].filter_type == 'ward' && zone_id != ''" class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[12].ward" (selectionChange)="onWardChange(allDashboardData[12].id, $event.value, 12)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[12].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[12].poi" (selectionChange)="onPoiChange(allDashboardData[12].id, $event.value, 12)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[12].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[12].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[12].id, $event.value, 12)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="mat-elevation-z8 mt-12 mr-8 bg-white">
          <h2 class="sub-dashoard-header lite-pink bdr-btm">Complaint Redressal</h2>
          <div class="tab_wrap">
            <table class="p-12 dashboard-table-wrapper pl-24">
              <tr> <th></th>
                <th></th>
                <th>Resolved</th>
                <th>Received</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/bad-review.png"/>
                </td>
                <td>
                  <small><a target="_blank" [routerLink]="['/kpi/view-report']" [queryParams]="{ kpi_id: allDashboardData.length && allDashboardData[13].kpi_id, date: report_date_minus_one | date : 'yyyy-MM-dd' }">{{allDashboardData.length && allDashboardData[13].name}}</a> (in No’s) </small>
                  <span class="yesterday">(Yesterday)</span>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[13].actual_value | number}}</span>
                </td>
                <td>
                  <span class="clr-blue">{{allDashboardData.length && allDashboardData[13].min_required | number}}</span>
                </td>

                <td>
                  <!-- <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> -->

                  <rg-gauge-chart *ngIf="allDashboardData.length"
                                  [canvasWidth]="100"
                                  [needleValue]="(allDashboardData[13].kpi_percentage| number : '1.1-1') ? (allDashboardData[13].kpi_percentage| number : '1.1-1') : 0"
                                  [options]="options"
                  ></rg-gauge-chart>
                  <!-- <img class="table-img-last" src="..../../assets/icons/icons_wireframe/sp1.jpg" /> -->
                </td>
                <td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[13].kpi_percentage| number : '1.1-1'}}%</span>
                </td>

                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <!-- <mat-form-field appearance="outline" *ngIf="allDashboardData[13].filter_type == 'ward' && zone_id != ''" class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[13].ward" (selectionChange)="onWardChange(allDashboardData[13].id, $event.value, 13)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[13].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[13].poi" (selectionChange)="onPoiChange(allDashboardData[13].id, $event.value, 13)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[13].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[13].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[13].id, $event.value, 13)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="mat-elevation-z8 mt-12 mr-8 bg-white">
          <h2 class="sub-dashoard-header lite-crim bdr-btm">Other Key Metrics</h2>
          <div class="tab_wrap">
            <table class="p-12 dashboard-table-wrapper pl-24">
              <tr> <th></th>
                <th></th>
                <!--<th>Achieved</th>-->
                <!--<th>Target</th>-->
                <!--<th></th>-->
                <!--<th></th>-->
                <th></th>
              </tr>
              <tr>
                <td>
                  <img class="table-img-first" src="../../../assets/icons/icons_wireframe/weight.png"/>
                </td>
                <td>
                  <small>{{allDashboardData.length && allDashboardData[14].name}} (in Tons)</small>
                  <span class="yesterday">(Yesterday)</span>
                </td>
                <td>
                  <span class="clr-grn">{{allDashboardData.length && allDashboardData[14].actual_value}}</span>
                </td>
                <!--<td>-->
                <!--<span class="clr-blue">{{allDashboardData.length && allDashboardData[14].min_required}}</span>-->
                <!--</td>-->

                <!--<td>-->
                <!--&lt;!&ndash; <progress id="file" value="116" max="220"> {{((116*100)/220)| number : '1.1-1'}}% </progress> &ndash;&gt;-->

                <!--<rg-gauge-chart *ngIf="allDashboardData.length"-->
                <!--[canvasWidth]="100"-->
                <!--[needleValue]="(allDashboardData[14].kpi_percentage| number : '1.1-1') ? (allDashboardData[14].kpi_percentage| number : '1.1-1') : 0"-->
                <!--[options]="options"-->
                <!--&gt;</rg-gauge-chart>-->
                <!--&lt;!&ndash; <img class="table-img-last" src="../../../assets/icons/icons_wireframe/sp1.jpg" /> &ndash;&gt;-->
                <!--</td>-->
                <!--<td><span class="percentage-count clr-blue">{{allDashboardData.length && allDashboardData[14].kpi_percentage| number : '1.1-1'}}%</span>-->
                <!--</td>-->
                <td *ngIf="allDashboardData.length && !prmsn?.userAllDetails?.roles.includes('gcc')" >
                  <!-- <mat-form-field appearance="outline" *ngIf="allDashboardData[14].filter_type == 'ward' && zone_id != ''" class="dropdown-fix">
                    <mat-label>Ward</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[14].ward" (selectionChange)="onWardChange(allDashboardData[14].id, $event.value, 14)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allWardsData" [value]="group.id">
                        {{group.region_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[14].filter_type == 'poi'" class="dropdown-fix">
                    <mat-label>POI</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[14].poi" (selectionChange)="onPoiChange(allDashboardData[14].id, $event.value, 14)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allPoisData" [value]="group.id">
                        {{group.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="allDashboardData[14].filter_type == 'vehicle_type'" class="dropdown-fix">
                    <mat-label>Vehicle Type</mat-label>
                    <mat-select matInput  name="zone" [(ngModel)]="allDashboardData[14].vehicle_type" (selectionChange)="onVehicleTypeChange(allDashboardData[14].id, $event.value, 14)">
                      <mat-option value="">Select</mat-option>
                      <mat-option *ngFor="let group of allVehicleTypesData" [value]="group.id">
                        {{group.vehicle_type_name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
            </table>
          </div>
        </div>

      </div>

    </div>
    <!-- / CONTENT CARD -->


  </div>
  <!-- / CENTER -->

</div>
