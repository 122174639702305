<div class="page-layout carded fullwidth">
  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- CONTENT HEADER -->
    <div class="header accent p-24" fxLayout="row" fxflex="50" fxLayoutAlign="center center">
      <!-- <img style="width: 100%; max-width: 120px;padding: 15px;" src="../../../../../assets/images/logos/imc_short.png"> -->


      <!-- <button mat-raised-button color="primary" type="button" style="margin-left: auto;" (click)="open()">Add</button> -->

      <!-- <img style="width: 100%; max-width: 200px;padding: 15px;" src="../../../../../assets/images/logos/SS-2020-League-Logo.png"> -->

      <img style="width: 100%; max-width: 200px;padding: 15px;" src="../../../../../assets/images/logos/logo_swm.png">

      <!-- <img style="width: 100%; max-width: 200px;padding: 15px;" src="../../../../../assets/images/logos/SS-2020-Logo.png"> -->


      <!-- <button mat-raised-button color="primary" type="button" style="margin-left: auto;" (click)="open()">Add</button> -->
    </div>
    <!-- / CONTENT HEADER -->
    <!-- <h1 style="text-align: center;color: #333;border-top: 1px solid #999;padding-top: 15px;
        margin-top: 0;"><span>Indore Swachh Ranking - April to June 2019</span></h1> -->

    <!-- CONTENT CARD -->
    <div class="content-card mb-24 add-edit thakyou-container">
      <form class="mat-card mat-elevation-z4 p-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
        name="form">
        <div fxLayout="column" fxLayoutAlign="start center">
          <h1 style="text-align: center;color: #333;
            margin-top: 0;"><span>Thank you for feedback !!</span></h1>
          <p class="thankx-class">
            Our assessor will take the required action soon.
          </p>

        </div>
      </form>
    </div>
  </div>
</div>