import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

// export interface DialogData {
//   animal: string;
//   name: string;
// }

@Component({
  selector: 'web-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.css']
})
export class QuestionListComponent implements OnInit {

  // =====================================================================================
  // ================================ Global Variable Declared ===========================
  // =====================================================================================

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  questionList = <any>{};

  dialogType: string;
  imageUrl: string;
  descp: string;
  registerName: string;
  RefType = "";

  constructor(private getApiService: ApiService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<QuestionListComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.dialogType = data.type;
    this.imageUrl = data.url;
    this.descp = data.descp;
  }

  ngOnInit() {
    if (this.dialogType == 'question') {
      this.getQuestionsList();
    }

    if (this.dialogType == 'register') {
      this.RefType = "New Register";
    }

    if (this.dialogType == 'dataentity') {
      this.RefType = "New Data Entity";
    }

  }

  // ============================================================================
  // =============================== Get Question List ==========================
  // ============================================================================

  getQuestionsList() {
    this.questionList = {};
    this.getApiService.get("question_categories").subscribe(res => {
      this.questionList = res;
    }, (error) => {
      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }

  onQuesSel(Qid, QuestionText) {
    var v = { "qid": Qid, "question": QuestionText };
    this.dialogRef.close(v);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onRegSave() {
    if (this.registerName != "" && this.registerName != undefined) {
      this.dialogRef.close(this.registerName);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
