<h1 mat-dialog-title>
  {{title}}
</h1>
 
<div mat-dialog-content>
  <p>{{message}}</p>
</div>


<div fxLayout="column" *ngIf = "showReasonField" style="margin-top: 20px;" fxLayoutAlign="start" fxLayout.gt-md="row">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="flex-wrap">
    <!-- <mat-form-field appearance="outline" class="flex-100">
      <mat-label>Reason</mat-label>
      <input matInput  [(ngModel)]="reason" required>
      <mat-error>Reason is required!</mat-error>
    </mat-form-field> -->

    <mat-form-field appearance="outline" class="flex-100">
      <mat-label>Reason</mat-label>
      <mat-select matInput *ngIf= "inputType != 'input'" [(ngModel)]="reason" required>
        <ng-container>
         <mat-option value="" disabled *ngIf="reasonStatusList.data && !reasonStatusList.data.length">No Data Found</mat-option>
          <mat-option *ngFor="let option of reasonStatusList.data" [value]="option.id">
            {{option?.status}}
          </mat-option>
        </ng-container>
      </mat-select>

      <input matInput  *ngIf= "inputType == 'input'"  [(ngModel)]="reason" required>
      <mat-error>Reason is required!</mat-error>
    </mat-form-field>
  </div>
</div>


 
<div mat-dialog-actions style="margin-top: 15px;"  *ngIf="!is_alert">
  <button mat-button (click)="onDismiss()">No</button>
  <button mat-raised-button color="primary" [disabled] = "(!reason && showReasonField)" (click)="onConfirm()">Yes</button>
</div>

<div mat-dialog-actions *ngIf="is_alert">
  <button mat-raised-button color="primary" (click)="onDismiss()">Ok</button>
</div>
