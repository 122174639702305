<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="http://iswm-indore.acceldash.com/assets/img/ba_short.png">
                </div>
            </div>

            <div class="px-8 px-md-16">
                <div class="app-name"> <span *ngIf = "getUlbByUlbId() && env && env?.environmentName == 'swm-suite'">{{getUlbByUlbId()}} - </span> {{env.headerName}}Smart Waste Management Suite</div>
            </div>

        </div>
      

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <mat-form-field appearance="outline" *ngIf ="env && env?.environmentName == 'swm-dev'" style="width: 195px;padding-top: 24px; font-size: initial;" class="pr-8">
                <mat-label style="font-size: 16px;">ULB Name</mat-label>
                <mat-select style="font-size: 16px;" (selectionChange)="onChangeUlb()" matInput [(ngModel)] = "ulb_id">
                  <ng-container>
                    <mat-option *ngFor="let option of ulbList" [value]="option.id">
                      {{option.name}}
                    </mat-option>
                  </ng-container>
                </mat-select>
            </mat-form-field>

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
            <img *ngIf="_prmsn?.userAllDetails?.data && _prmsn?.userAllDetails?.data.length && _prmsn?.userAllDetails?.data[0].avatar; else elseBlock"  class="avatar mr-0 mr-sm-16" [src]="getApiService.imageUrl+'/'+_prmsn?.userAllDetails?.data[0].avatar">
               <ng-template #elseBlock>
                 <img  class="avatar mr-0 mr-sm-16" src="assets/images/avatars/user.png">
               </ng-template>

                  <span class="username mr-12" fxHide fxShow.gt-sm>{{_prmsn?.userAllDetails?.data && _prmsn?.userAllDetails?.data[0].employee?.first_name}} </span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item [routerLink]="'/my-profile/basic-information'">
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>

                <!-- <button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <span>Inbox</span>
                </button> -->

                <button mat-menu-item class="" (click)="logOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <!-- <div class="toolbar-separator"></div>

            <fuse-search-bar (input)="search($event)"></fuse-search-bar>

            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->

        </div>

    </div>

</mat-toolbar>
