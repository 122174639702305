<h1 mat-dialog-title *ngIf="dialogType == 'question'">Question List</h1>
<div mat-dialog-content *ngIf="dialogType == 'question'">
  <div class="p-8" fxLayout="column" fxLayoutAlign="start" *ngFor="let data of questionList.data">
    <!--Accordion Row Start-->
    <mat-accordion class="question-accordion">

      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">

        <mat-expansion-panel-header>
          <mat-panel-title>
            {{data.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="p-8" fxLayout="column" fxLayoutAlign="start" *ngFor="let d of data.childs; let sc=index">
          <mat-accordion class="question-accordion">

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">

              <mat-expansion-panel-header>
                <mat-panel-title>
                  SC{{sc+1}}. {{d.name}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list *ngIf="d.questions.length > 0">
                <mat-list-item>
                  <div> <b>Question</b></div>
                </mat-list-item>
                <mat-list-item *ngFor="let c of d.questions; let p=index"
                  style="height: 100% !important; padding-bottom: 10px">
                  <div><a (click)="onQuesSel(c.id, c.question_text)">Q{{p+1}}. {{c.question_text}}</a></div>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="p-8" fxLayout="column" fxLayoutAlign="start">
          <mat-accordion class="question-accordion">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Question
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item *ngFor="let d of data.questions; let p=index"
                  style="height: 100% !important; padding-bottom: 10px">
                  <div><a (click)="onQuesSel(d.id, d.question_text)"> Q{{p+1}}. {{d.question_text}}</a></div>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <!--Accordion Row End -->
  </div>
</div>

<!-- <h1 mat-dialog-title *ngIf="dialogType == 'image'">Image List</h1> -->
<div mat-dialog-content *ngIf="dialogType == 'image'">

  <img style="max-width:560px; max-height: 400px" src="{{imageUrl}}">
  <h4 style="text-align: center;">{{descp}}</h4>

</div>


<h1 mat-dialog-title *ngIf="dialogType == 'register' || dialogType == 'dataentity'">{{RefType}}</h1>
<div mat-dialog-content *ngIf="dialogType == 'register' || dialogType == 'dataentity'">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field appearance="outline" fxFlex="100" class="pr-4">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="registerName" required>
      <mat-error>Name is required!</mat-error>
    </mat-form-field>
  </div>
  <div class="ng-button-row">
    <button mat-raised-button color="accent" (click)="onRegSave()">Submit</button>
    <button mat-raised-button color="warn" type="button" (click)="close()">Close</button>
  </div>
</div>