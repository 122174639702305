import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ApiService } from '../../service/api.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../navigation/navigation';
import {PermissionsService} from '../../service/permissions.service';
import { environment } from '../../../environments/environment';
import { ConfirmUlbDialogComponent } from 'app/confirm-ulb-dialog/confirm-ulb-dialog.component';
import { ConfirmDialogModel } from 'app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})


export class LoginComponent implements OnInit {


  loginForm: FormGroup;
  env = environment;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(private getApiService: ApiService,
              private _fuseConfigService: FuseConfigService,
              private _formBuilder: FormBuilder,
              public snackBar: MatSnackBar,
              private router: Router,
              public nav: NavigationService,
              public prmsn: PermissionsService,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog
  ) {

    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    let  username = '';
    let password = '';
    let returnUrl = '';
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.returnUrl){
          returnUrl =  params.returnUrl.split('?')[0];
          username =  params.returnUrl.split('?username=').pop().split('&')[0];
          password = params.returnUrl.split('password=')[1];
      }
    });
    this.loginForm = this._formBuilder.group({
      email: [username && password ? username : '', [Validators.required, Validators.email]],
      password: [username && password ? password : '', Validators.required]
    });

    if(username && password && returnUrl) {
      this.login(returnUrl);
    }
  }

  login(returnUrl?) {

    let formObj = this.loginForm.getRawValue();

    this.getApiService.post('login', formObj).subscribe(res => {
      let responsedata = res;
      if (responsedata.status_code == 200 && responsedata.data.token != undefined) {

        localStorage.setItem('currentUser', JSON.stringify(responsedata.data.token));
        localStorage.setItem('loginStatus', 'true');
        this.prmsn.getUserDetailsSrvr().then( (suc) => {
          this.nav.status = true;
          this.nav.setNavConfig().then(() => {
            if(returnUrl) {
              this.loginForm.reset();
              this.router.navigate([returnUrl]);
            } else {
              if(this.prmsn.isAccessPage('dashboard')) {
                this.router.navigate(['/dashboard']);
              } else if(this.prmsn.isAccessPage('grievance_management_dashboard')) {
                this.router.navigate(['/grievance-management/grievance-management-dashboard']);
              } else {
                this.router.navigate(['/swm-dashboard']);
              }
            }
            if(this.env && this.env.environmentName && this.env.environmentName == 'swm-dev') {
              this.confirmUlb();
            }

          });
        });

      }

      if (responsedata.success === true) {
        this.snackBar.open(responsedata.message, 'Success', {
          duration: 2000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });

      }
    }, (error) => {

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  confirmUlb() {
    const dialogData = new ConfirmDialogModel("Select ULB", ``, null, true);
    this.dialog.open(ConfirmUlbDialogComponent, { maxWidth: "400px", data: dialogData }).afterClosed().subscribe(result => {
      if (result) {
       
      }
    });

  }
}

