import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { PermissionsService } from '../../service/permissions.service';

@Component({
  selector: 'web-dashboard-prototype',
  templateUrl: './dashboard-prototype.component.html',
  styleUrls: ['./dashboard-prototype.component.scss']
})
export class DashboardPrototypeComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  region_id = '';
  allDashboardData: any = {};
  options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['rgb(44, 151, 222)', 'lightgray', 'green'],
    arcDelimiters: [30, 60],
    rangeLabel: ['0', '100'],
    needleStartValue: 0
  };
  allPackagesData = [];
  allZonesData = [];
  allWardsData = [];
  package_id = '';
  shift_id = '';
  zone_id = '';
  allPoisData = [];
  allVehicleTypesData = [];
  modelData = {};
  maxDate = moment(new Date()).format('YYYY-MM-DD');;
  workinghoursList = <any>{};
  report_date = moment(new Date()).format('YYYY-MM-DD');
  report_date_minus_one = moment(new Date()).subtract(1, "days");

  constructor(private getApiService: ApiService,
              private _formBuilder: FormBuilder,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              private appComponent: AppComponent,
              public prmsn: PermissionsService) {
  }

  ngOnInit(): void {
    this.getModelData('regionHierarchyUlb');
    this.getAllPoisData();
    this.getAllVehicleTypesData();
    this.getAllReportsData();
    this.getAllWorkHourList();
  }

  getAllReportsData() {
    this.appComponent.showLoader();
    let report_date = moment(this.report_date).format('YYYY-MM-DD');
    this.getApiService.get('gcc_kpi_dashboard?region_id=' + this.region_id + '&date=' + report_date + '&shift_id=' + this.shift_id).subscribe(res => {
      this.appComponent.hideLoader();
      this.allDashboardData = res.data;
    }, (error) => {
      this.appComponent.hideLoader();

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllWorkHourList() {
    this.getApiService.get("working_hours_shift").subscribe(res => {
      this.workinghoursList = res;
      this.workinghoursList.data.sort(function(a, b) {
        var nameA = a.shift_name; // ignore upper and lowercase
        var nameB = b.shift_name; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }, (error) => {
    });
  }

  getDashboardDataForWardKPIData(_kpi_id, _ward_id, _index, _poi, _vehicle_type) {
    let report_date = moment(this.report_date).format('YYYY-MM-DD');

    let _region_id = '';
    if (_ward_id == '') {
      _region_id = this.region_id;
    } else {
      _region_id = _ward_id;
    }
    this.appComponent.showLoader();
    this.getApiService.get('gcc_kpi_value/' + _kpi_id + '?region_id=' + _region_id + '&poi_id=' + _poi + '&vehicle_type_id=' + _vehicle_type
      + '&date=' + report_date).subscribe(res => {
      this.appComponent.hideLoader();
      res.data.vehicle_type = _vehicle_type;
      res.data.poi = _poi;
      res.data.ward = _ward_id;
      this.allDashboardData[_index] = res.data;
    }, (error) => {
      this.appComponent.hideLoader();

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getModelData(field_name) {
    this.getApiService.get('model_data/26').subscribe(res => {
      this.modelData[field_name] = JSON.parse(JSON.stringify([res.data]));
      this.modelData[field_name][0]['field_index'] = 0;
      this.modelData['regionHierarchyUlb'][0].ngmodel = '';

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  modelChange(_value, _model, field_name, _index, model_id) {
    console.log(_value);
    console.log(_model);
    this.allZonesData = [];
    this.allWardsData = [];
    this.zone_id = '';
    if (_value == '') {
      this.region_id = '';
    } else {

      let modelData = JSON.parse(JSON.stringify(this.modelData[field_name]));

      let i = modelData.length; //initialize counter to array length

      while (i--) //decrement counter
      {
        if ((this.modelData[field_name][i] && this.modelData[field_name][i].field_index) > _index) {
          this.modelData[field_name].splice(i, 1);
        }

      }

      this.region_id = _value.value;

      if (_value.field_data.length <= 0) {

      }
      else {
        let index = this.modelData[field_name].findIndex(item => item.field_label == _value.field_label);
        if (index == -1) {
          _value.field_index = _index + 1;
          _value.ngmodel = '';
          this.modelData[field_name].push(_value);
        }
      }

      if (_value.value != '') {
        this.getAllZonesData();
      }
    }
    this.getAllReportsData();


  }

  getAllZonesData() {
    this.getApiService.get('child_regions/' + this.region_id).subscribe(res => {

      this.allZonesData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllWardsData() {
    this.getApiService.get('child_regions/' + this.region_id).subscribe(res => {

      this.allWardsData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllPoisData() {
    this.getApiService.get('pois?poi_type_id=5').subscribe(res => {

      this.allPoisData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllVehicleTypesData() {
    this.getApiService.get('vehicle_types').subscribe(res => {

      this.allVehicleTypesData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  onZoneChange() {
    this.allWardsData = [];
    this.region_id = this.zone_id;
    if (this.zone_id != '') {
      this.getAllWardsData();
    }
    this.getAllReportsData();
  }

  onShiftChange() {
    this.getAllReportsData();
  }

  onDatechange(){
    this.report_date_minus_one = moment(new Date(this.report_date)).subtract(1, "days");
    this.getAllReportsData();
  }

  onWardChange(_kpi_id, _ward_id, _index) {
    this.getDashboardDataForWardKPIData(_kpi_id, _ward_id, _index, '', '');
  }

  onPoiChange(_kpi_id, _poi_id, _index) {
    this.getDashboardDataForWardKPIData(_kpi_id, '', _index, _poi_id, '');
  }

  onVehicleTypeChange(_kpi_id, _vehicle_type_id, _index) {
    this.getDashboardDataForWardKPIData(_kpi_id, '', _index, '', _vehicle_type_id);
  }

}
