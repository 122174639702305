import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { PermissionsService } from '../../service/permissions.service';
import { FormBuilder,FormControl, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'web-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  complaintStatusList = <any>{};
  taskDashboard = <any>{};
  Attendance  = <any>{};
  DeviceStatuses = <any>{};
  VehicleStatus = <any>{};

  constructor(private getApiService: ApiService,
    private _formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public _prmsn: PermissionsService) {
  }

  ngOnInit() {
    // this.getAllcolplaintStatusList();
    // this.getAlltaskDashboard();
    // this.getAllAttendance();
    // this.getAllDeviceStatuses();
    // this.getAllVehicleStatus();
  }

  getAllcolplaintStatusList() {
    this.getApiService.get("complaint_status_count").subscribe(res => {
      this.complaintStatusList = res.data;
    }, (error) => {

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }

  getAlltaskDashboard() {
    this.getApiService.get("task_dashboard").subscribe(res => {
      this.taskDashboard = res.data;
    }, (error) => {

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }

  getAllAttendance() {
    this.getApiService.getpaytime("dashboard_card_base").subscribe(res => {
      this.Attendance = res.data;
    }, (error) => {

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }

  getAllDeviceStatuses() {
    this.getApiService.getpaytime("dashboard_device_status").subscribe(res => {
      this.DeviceStatuses = res.data;
    }, (error) => {

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }

  getAllVehicleStatus() {
    this.getApiService.get("status_wise_vehicle_count ").subscribe(res => {
      this.VehicleStatus = res.data;
    }, (error) => {

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }

}
