import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatCarouselComponent } from '@ngmodule/material-carousel';
import { MatMenuTrigger } from '@angular/material/menu';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'web-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {
  feedback_id: number;
  successMsg: string;
  errorMsg: string;
  @ViewChild('imagesCarousel', {static: false}) imagesCarousel: MatCarouselComponent;

  form: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialogRef: MatDialogRef<ImageDialogComponent>,
    private getApiService: ApiService,
    private _formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.feedback_id = data.id ? data.id : '';
    this.successMsg = data.successMsg ? data.successMsg : '';
    this.errorMsg = data.errorMsg ? data.errorMsg : '';
  }

  ngOnInit() {
    this.CreateForm();
  }

  CreateForm() {
    this.form = this._formBuilder.group({
      mobno: ['', Validators.required],
      name: ['', Validators.required]
    });
  }

  download(value) {
    // this.getApiService.getBlobFileFromServer(value.actual_path).subscribe(res => {
    //   let blob:any = new Blob([res], { type: res.type });
    //   const url = window.URL.createObjectURL(blob);
    //   const imageArry =  value.actual_path.split('/uploads') ? value.actual_path.split('/uploads') : ['','download'];
    //   fileSaver.saveAs(blob, imageArry[1]);
    // }, (error) => {
    //   this.snackBar.open(error.message, 'Error', {
    //     duration: 2500,
    //     horizontalPosition: this.horizontalPosition,
    //     verticalPosition: this.verticalPosition,
    //   });
    // });

  
  this.getApiService.getBase64ImageFromURL(value.actual_path).subscribe(base64data => {
   let base64Image = "data:image/jpg;base64," + base64data;
    // save image to disk
    var link = document.createElement("a");

    document.body.appendChild(link); // for Firefox

    link.setAttribute("href", base64Image);
    const imageArry =  value.actual_path.split('/uploads') ? value.actual_path.split('/uploads') : ['','download'];

    link.setAttribute("download", imageArry[1]);
    link.click();
  });
  }


  Save() {

    let formObj = this.form.getRawValue();
    let data = formObj;

    this.getApiService.put("citizen_entity_feedback/" + this.feedback_id, data).subscribe(res => {
      var responsedata = res;
      if (responsedata.success == true) {
        this.dialogRef.close();
       
        this.snackBar.open(responsedata.message, 'Success', {
          duration: 2000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });

      }
    }, (error) => {

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }

  close(){
    this.dialogRef.close();
  }

}
