import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'app/service/api.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'web-assign-ulb-dialog',
  templateUrl: './assign-ulb-dialog.component.html',
  styleUrls: ['./assign-ulb-dialog.component.scss']
})
export class AssignUlbDialogComponent implements OnInit {

  title: string;
  message: string;
  ulbList = [];
  ulb_id = [];
  userId: string;
  selectedUlbList = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(public dialogRef: MatDialogRef<AssignUlbDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private getApiService:ApiService,
    public snackBar: MatSnackBar,
    ) {
    this.title = data.title;
    this.message = data.message;
    this.userId = data.userId;
    this.selectedUlbList = data.selectedUlbList;
    this.ulb_id = [];
   
  }
  ngOnInit(): void {
    this.getAllUlbList();
  }


  onConfirm(): void {
    const ulb_id = [];
    this.ulb_id.map(res => {
      ulb_id.push(res.id);
    })
    const data = {
      ulb_ids: ulb_id,
      user_id: this.userId
    }
    this.getApiService.post('user_ulbs', data).subscribe(res => {
      this.dialogRef.close(true);
      this.snackBar.open(res.message, 'Success', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.dialogRef.close(true);
    }, (error) => {
      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }


  onDismiss(): void {
    this.dialogRef.close(false);
  }

  getAllUlbList() {
    this.getApiService.get("ulbs").subscribe(res => {
      this.ulbList = res.data;
        this.ulbList.sort(function(a, b) {
        var nameA = a.name; // ignore upper and lowercase
        var nameB = b.name; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      this.selectedUlbList.map(res => {
        const ulbObject =  this.ulbList.find(ulb => ulb.id == res.id);
        this.ulb_id.push(ulbObject);
       });

    }, (error) => {

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }
}

export class ConfirmDialogModel1 {

  constructor(public title: string, public message: string,public userId:any,public selectedUlbList:any) {
  }
}


