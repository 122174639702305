// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  socketUrl: 'wss://swm-api-dev.acceldash.com',
  apiUrl: 'https://swm-api-dev.acceldash.com/api/v1/',
  imageUrl: 'https://swm-api-dev.acceldash.com',
  environmentName: 'swm-dev',
  headerName: '',
  loginLogo: 'ba_logo.png',
  sideLogo: 'ba_short.png',
  c_lat: 22.718673,
  c_lng: 75.855920,
  hideBranding: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
