<div class="page-layout carded fullwidth">

  <!-- TOP BACKGROUND -->
  <div class="top-bg accent"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- CONTENT HEADER -->
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="start center">
      <h2 class="m-0">Dashboard</h2>
    </div>
    <!--&lt;!&ndash; / CONTENT HEADER &ndash;&gt;-->

    <!--&lt;!&ndash; CONTENT CARD &ndash;&gt;-->
    <!--<div class="content-card mb-24 add-edit">-->

      <!--&lt;!&ndash; CONTENT TOOLBAR &ndash;&gt;-->
      <!--<div class="toolbar px-24 py-8">-->
        <!--<span>Vehicle</span>-->
      <!--</div>-->
      <!--&lt;!&ndash; / CONTENT TOOLBAR &ndash;&gt;-->

      <!--<div fxLayout="row" class="Status-selection-web" style="height: 160px !important; max-height: 200px !important;">-->

        <!--<fuse-widget class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" [routerLink]="['/vehicle-tracking/vehicle-location']" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_blue">-->
                <!--{{VehicleStatus.total_vehicle_count}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Total-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" [routerLink]="['/vehicle-tracking/vehicle-location']" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_grn">-->
                <!--{{VehicleStatus.not_moving_vehicle_count}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Moving-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" [routerLink]="['/vehicle-tracking/vehicle-location']" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_ylow">-->
                <!--{{(+VehicleStatus.not_moving_vehicle_count) + (+VehicleStatus.parked_vehicle_count)}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Other-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->
      <!--</div>-->

    <!--</div>-->
    <!--&lt;!&ndash; / CONTENT CARD &ndash;&gt;-->

    <!--&lt;!&ndash; CONTENT CARD &ndash;&gt;-->
    <!--<div class="content-card mb-24 add-edit">-->

      <!--&lt;!&ndash; CONTENT TOOLBAR &ndash;&gt;-->
      <!--<div class="toolbar px-24 py-8">-->
        <!--<span>Tasks</span>-->
      <!--</div>-->
      <!--&lt;!&ndash; / CONTENT TOOLBAR &ndash;&gt;-->

      <!--<div fxLayout="row" class="Status-selection-web" style="height: 160px !important; max-height: 200px !important;">-->

        <!--<fuse-widget class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_blue">-->
                <!--{{taskDashboard.immediately_due_count}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Immediate-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_ylow">-->
                <!--{{taskDashboard.pending_count}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Pending-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_grn">-->
                <!--{{taskDashboard.completed_count}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Completed-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->
      <!--</div>-->

    <!--</div>-->
    <!--&lt;!&ndash; / CONTENT CARD &ndash;&gt;-->

    <!--&lt;!&ndash; CONTENT CARD &ndash;&gt;-->
    <!--<div class="content-card mb-24 add-edit">-->

      <!--&lt;!&ndash; CONTENT TOOLBAR &ndash;&gt;-->
      <!--<div class="toolbar px-24 py-8">-->
        <!--<span>Grievance</span>-->
      <!--</div>-->
      <!--&lt;!&ndash; / CONTENT TOOLBAR &ndash;&gt;-->

      <!--<div fxLayout="row" class="Status-selection-web" style="height: 160px !important; max-height: 200px !important;">-->

        <!--<fuse-widget [routerLink]="['/grievance-management/grievance-management-dashboard']" class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_blue">-->
                <!--{{complaintStatusList.Open}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Open-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget [routerLink]="['/grievance-management/grievance-management-dashboard']" class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_ylow">-->
                <!--{{complaintStatusList['In Progress']}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">In Progress-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget [routerLink]="['/grievance-management/grievance-management-dashboard']" class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_grn">-->
                <!--{{complaintStatusList.Completed}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Completed-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->
      <!--</div>-->

    <!--</div>-->
    <!--&lt;!&ndash; / CONTENT CARD &ndash;&gt;-->

    <!--&lt;!&ndash; CONTENT CARD &ndash;&gt;-->
    <!--&lt;!&ndash; <div class="content-card mb-24 add-edit">-->

      <!--<div class="toolbar px-24 py-8">-->
        <!--<span>Bin Unloading</span>-->
      <!--</div>-->
      <!--<div fxLayout="row" class="Status-selection-web" style="height: 160px !important; max-height: 200px !important;">-->

        <!--<fuse-widget [routerLink]="['/grievance-management/grievance-management-dashboard']" class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_blue">-->
                <!--{{DeviceStatuses.Open}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Open-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget [routerLink]="['/grievance-management/grievance-management-dashboard']" class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_ylow">-->
                <!--{{DeviceStatuses.s}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">In progress-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget [routerLink]="['/grievance-management/grievance-management-dashboard']" class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_grn">-->
                <!--{{DeviceStatuses.Completed}}-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Closed-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->
      <!--</div>-->

    <!--</div> &ndash;&gt;-->
    <!--&lt;!&ndash; / CONTENT CARD &ndash;&gt;-->

    <!--&lt;!&ndash; CONTENT CARD &ndash;&gt;-->
    <!--<div class="content-card mb-24 add-edit">-->

      <!--&lt;!&ndash; CONTENT TOOLBAR &ndash;&gt;-->
      <!--<div class="toolbar px-24 py-8">-->
        <!--<span>Attandance</span>-->
      <!--</div>-->
      <!--&lt;!&ndash; / CONTENT TOOLBAR &ndash;&gt;-->

      <!--<div fxLayout="row" class="Status-selection-web" style="height: 160px !important; max-height: 200px !important;">-->

        <!--<fuse-widget class="widget pointer_cursor widget_box" -->
        <!--fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div [routerLink]="['/attendance-reports/employee-in-out-count']" class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_blue">-->
                <!--7708-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Total Employees-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget class="widget pointer_cursor widget_box" -->
        <!--[routerLink]="['/attendance-reports/employee-in-out-count']" fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_ylow">-->
                <!--3949-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Minimum Required-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->

        <!--<fuse-widget class="widget pointer_cursor widget_box" -->
        <!--[routerLink]="['/attendance-reports/employee-in-out-count']" fxLayout="column" fxFlex="100" fxFlex.gt-md="33">-->
    <!---->
          <!--<div class="fuse-widget-front" style="height: 100%; max-height: 112px;">-->
    <!---->
            <!--<div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">-->
              <!--<div class="font-size-50 line-height-57 widget_number_grn">-->
                <!--254-->
              <!--</div>-->
              <!--<div class="h3 secondary-text font-weight-500 widget_title" style="text-align: center;">Total Present-->
              <!--</div>-->
            <!--</div>-->
    <!---->
          <!--</div>-->
        <!--</fuse-widget>-->
      <!--</div>-->

    <!--</div>-->
    <!-- / CONTENT CARD -->

  </div>
  <!-- / CENTER -->

</div>
