import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { PermissionsService } from '../../service/permissions.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'web-dashboard-p5',
  templateUrl: './dashboard-p5.component.html',
  styleUrls: ['./dashboard-p5.component.scss']
})
export class DashboardP5Component implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  region_id: any = '';
  allDashboardData: any = {};
  options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['rgb(44, 151, 222)', 'lightgray', 'green'],
    arcDelimiters: [30, 60],
    rangeLabel: ['0', '100'],
    needleStartValue: 0
  };
  allPackagesData = [];
  allZonesData = [];
  allWardsData = [];
  package_id: any = '';
  shift_id = '';
  zone_id = '';
  allPoisData = [];
  allVehicleTypesData = [];
  modelData = {};
  maxDate = moment(new Date()).format('YYYY-MM-DD');;
  workinghoursList = <any>{};
  report_date = moment(new Date()).format('YYYY-MM-DD');
  report_date_minus_one = moment(new Date()).subtract(1, "days");

  constructor(private getApiService: ApiService,
              private _formBuilder: FormBuilder,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              private appComponent: AppComponent,
              public prmsn: PermissionsService,
              public _route: ActivatedRoute) {
    this.package_id = (this._route.routeConfig.path == 'dashboard-p5') ? 188 : 189;
    this.region_id = this.package_id;
  }

  ngOnInit(): void {
    this.getAllPoisData();
    this.getAllVehicleTypesData();
    this.getAllReportsData();
    this.getAllWorkHourList();
    this.getAllZonesData();
  }

  getAllReportsData() {
    this.appComponent.showLoader();
    let report_date = moment(this.report_date).format('YYYY-MM-DD');
    this.getApiService.get('gcc_kpi_dashboard?region_id=' + this.region_id + '&date=' + report_date + '&shift_id=' + this.shift_id).subscribe(res => {
      this.appComponent.hideLoader();
      this.allDashboardData = res.data;
    }, (error) => {
      this.appComponent.hideLoader();

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllWorkHourList() {
    this.getApiService.get("working_hours_shift").subscribe(res => {
      this.workinghoursList = res;
      this.workinghoursList.data.sort(function(a, b) {
        var nameA = a.shift_name; // ignore upper and lowercase
        var nameB = b.shift_name; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }, (error) => {
    });
  }

  getDashboardDataForWardKPIData(_kpi_id, _ward_id, _index, _poi, _vehicle_type) {
    let report_date = moment(this.report_date).format('YYYY-MM-DD');

    let _region_id = '';
    if (_ward_id == '') {
      _region_id = this.region_id;
    } else {
      _region_id = _ward_id;
    }
    this.appComponent.showLoader();
    this.getApiService.get('gcc_kpi_value/' + _kpi_id + '?region_id=' + _region_id + '&poi_id=' + _poi + '&vehicle_type_id=' + _vehicle_type
      + '&date=' + report_date).subscribe(res => {
      this.appComponent.hideLoader();
      res.data.vehicle_type = _vehicle_type;
      res.data.poi = _poi;
      res.data.ward = _ward_id;
      this.allDashboardData[_index] = res.data;
    }, (error) => {
      this.appComponent.hideLoader();

      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }



  getAllZonesData() {
    this.getApiService.get('child_regions/' + this.region_id).subscribe(res => {

      this.allZonesData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllWardsData() {
    this.getApiService.get('child_regions/' + this.region_id).subscribe(res => {

      this.allWardsData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllPoisData() {
    this.getApiService.get('pois?poi_type_id=5').subscribe(res => {

      this.allPoisData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  getAllVehicleTypesData() {
    this.getApiService.get('vehicle_types').subscribe(res => {

      this.allVehicleTypesData = res.data;

    }, (error) => {

      this.snackBar.open(error.error.error[0].message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }

  onZoneChange() {
    this.allWardsData = [];
    this.region_id = this.zone_id;
    if (this.zone_id != '') {
      this.getAllWardsData();
    } else {
      this.region_id = this.package_id;
    }
    this.getAllReportsData();
  }

  onShiftChange() {
    this.getAllReportsData();
  }

  onDatechange(){
    this.report_date_minus_one = moment(new Date(this.report_date)).subtract(1, "days");
    this.getAllReportsData();
  }

  onWardChange(_kpi_id, _ward_id, _index) {
    this.getDashboardDataForWardKPIData(_kpi_id, _ward_id, _index, '', '');
  }

  onPoiChange(_kpi_id, _poi_id, _index) {
    this.getDashboardDataForWardKPIData(_kpi_id, '', _index, _poi_id, '');
  }

  onVehicleTypeChange(_kpi_id, _vehicle_type_id, _index) {
    this.getDashboardDataForWardKPIData(_kpi_id, '', _index, '', _vehicle_type_id);
  }

}
