import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'app/service/api.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'web-confirm-ulb-dialog',
  templateUrl: './confirm-ulb-dialog.component.html',
  styleUrls: ['./confirm-ulb-dialog.component.scss']
})
export class ConfirmUlbDialogComponent implements OnInit {

  title: string;
  message: string;
  ulbList = <any>{};
  ulb_id = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(public dialogRef: MatDialogRef<ConfirmUlbDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private getApiService:ApiService,
    public snackBar: MatSnackBar,
    ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
  }
  ngOnInit(): void {
    this.getAllUlbList();
  }

  onConfirm(): void {
    const data = {
      default_ulb_id: this.ulb_id
    }
    this.getApiService.post('update_default_ulb', data).subscribe(res => {
      location.reload();
      this.snackBar.open(res.message, 'Success', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.dialogRef.close(true);
    }, (error) => {
      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }


  onDismiss(): void {
    this.dialogRef.close(false);
  }

  getAllUlbList() {
    this.getApiService.get("get_ulbs").subscribe(res => {
      this.ulbList = res.data ? res.data.ulbs : [];
      const defaultUlb = res.data  && res.data.employee && res.data.employee.default_ulb  ? res.data.employee.default_ulb : {name: '' , id: ''};
      this.ulb_id =  defaultUlb ? defaultUlb.id : '';
      if(defaultUlb.name && defaultUlb.id && !this.ulbList.length){
        this.ulbList.push({name: defaultUlb.name, id: defaultUlb.id});
      }
        this.ulbList.sort(function(a, b) {
        var nameA = a.name; // ignore upper and lowercase
        var nameB = b.name; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });

    }, (error) => {
      this.snackBar.open(error.message, 'Error', {
        duration: 2500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }
}

export class ConfirmDialogModel {

  constructor(public title: string, public message: string) {
  }
}


