import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster'

import 'hammerjs';
import { AgGridModule } from 'ag-grid-angular';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { fuseConfig } from 'app/fuse-config';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { FormsModule } from '@angular/forms';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { LoginComponent } from './main/login/login.component';

import { AuthInterceptor } from './helpers/auth.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ColorPickerModule } from 'ngx-color-picker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { TreeviewModule } from 'ngx-treeview';
import { Ng5SliderModule } from 'ng5-slider';
import { GaugeChartModule } from 'angular-gauge-chart';

import { EntityManagementModule } from './main/entity-management/entity-management.module';
import { ImageDialogComponent } from './main/image-dialog/image-dialog.component';
import { ChartsModule } from 'ng2-charts';
import { ThankYouComponent } from './main/thank-you/thank-you.component';
import { QuestionListComponent } from './main/register-management/question-list/question-list.component';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { GridsterModule } from 'angular-gridster2';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { DashboardPrototypeComponent } from './main/dashboard-prototype/dashboard-prototype.component';
import {LeaveManagementModule} from './main/leave-management/leave-management.module';
import { ConfirmUlbDialogComponent } from './confirm-ulb-dialog/confirm-ulb-dialog.component';
import { AssignUlbDialogComponent } from './assign-ulb-dialog/assign-ulb-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TempDashboardComponent } from './main/temp-dashboard/temp-dashboard.component';
import { DashboardP5Component } from './main/dashboard-p5/dashboard-p5.component';

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'old-dashboard',
    component: DashboardPrototypeComponent,
  },
  {
    path: 'dashboard',
    component: TempDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-p5',
    component: DashboardP5Component,
  },
  {
    path: 'dashboard-p2',
    component: DashboardP5Component,
  },
  {
    path: 'swm-dashboard',
    component: DashboardComponent
  },
  {
    path: 'sample',
    loadChildren: () => import('./main/sample/sample.module').then(m => m.SampleModule),
  },
  {
    path: 'system-profile',
    loadChildren: () => import('./main/swm/swm.module').then(m => m.SwmModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'my-profile',
    loadChildren: () => import('./main/my-profile/my-profile.module').then(m => m.MyProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'state-profiling',
    loadChildren: () => import('./main/state-profiling/state-profiling.module').then(m => m.StateProfilingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ulb-profiling',
    loadChildren: () => import('./main/ulb-profiling/ulb-profiling.module').then(m => m.UlbProfilingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'resource',
    loadChildren: () => import('./main/resource/resource.module').then(m => m.ResourceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'public-notification',
    loadChildren: () => import('./main/public-notification/public-notification.module').then(m => m.PublicNotificationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register-management',
    loadChildren: () => import('./main/register-management/register-management.module').then(m => m.RegisterManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vtms',
    loadChildren: () => import('./main/vtms/vtms.module').then(m => m.VtmsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'compliance-management',
    loadChildren: () => import('./main/compliance-management/compliance-management.module').then(m => m.ComplianceManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'registers',
    loadChildren: () => import('./main/registers/registers.module').then(m => m.RegistersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'master-data-management',
    loadChildren: () => import('./main/entity-management/entity-management.module').then(m => m.EntityManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'qr-management',
    loadChildren: () => import('./main/qr-management/qr-management.module').then(m => m.QrManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'citizen_engagement',
    loadChildren: () => import('./main/citizen-engagement/citizen-engagement.module').then(m => m.CitizenEngagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'job-scheduler',
    loadChildren: () => import('./main/job-scheduler/job-scheduler.module').then(m => m.JobSchedulerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'maintenance-scheduler',
    loadChildren: () => import('./main/maintenance-scheduler/maintenance-scheduler.module').then(m => m.MaintenanceSchedulerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'beat-scheduler',
    loadChildren: () => import('./main/beat-scheduler/beat-scheduler.module').then(m => m.BeatSchedulerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'thank-you',
    component: ThankYouComponent
  },
  {
    path: 'contract-management',
    loadChildren: () => import('./main/contract-management/contract-management.module').then(m => m.ContractManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'citizen-verification-management',
    loadChildren: () => import('./main/citizen-verification/citizen-verification.module').then(m => m.CitizenVerificationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'beat-planning',
    loadChildren: () => import('./main/beat-planning/beat-planning.module').then(m => m.BeatPlanningModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'grievance-management',
    loadChildren: () => import('./main/grievance-management/grievance-management.module').then(m => m.GrievanceManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'system-configurations',
    loadChildren: () => import('./main/system-configurations/system-configurations.module').then(m => m.SystemConfigurationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./main/report/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'playback',
    loadChildren: () => import('./main/playback/playback.module').then(m => m.PlaybackModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./main/authentications/authentications.module').then(m => m.AuthenticationsModule),
  },
  {
    path: 'rfid-reports',
    loadChildren: () => import('./main/static-reports/static-reports.module').then(m => m.StaticReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vehicle-tracking',
    loadChildren: () => import('./main/vehicle-location/vehicle-location.module').then(m => m.VehicleLocationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'attendance-reports',
    loadChildren: () => import('./main/attendance-reports/attendance-reports.module').then(m => m.AttendanceReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'kpi',
    loadChildren: () => import('./main/kpi/kpi.module').then(m => m.KpiModule)
  },
  {
    path: 'kpi-reports',
    loadChildren: () => import('./main/kpi-reports/kpi-reports.module').then(m => m.KpiReportsModule)
  },
  {
    path: 'task-management',
    loadChildren: () => import('./main/task-management/task-management.module').then(m => m.TaskManagementModule)
  },
  {
    path: 'pois-management',
    loadChildren: () => import('./main/pois/pois.module').then(m => m.PoisModule)
  },
  {
    path: 'file-management',
    loadChildren: () => import('./main/file-management/file-management.module').then(m => m.FileManagementModule)
  },
  {
    path: 'asset-management',
    loadChildren: () => import('./main/asset-management/asset-management.module').then(m => m.AssetManagementModule)
  },
  {
    path: 'vehicle-procurement',
    loadChildren: () => import('./main/vehicle-proqurement/vehicle-proqurement.module').then(m => m.VehicleProqurementModule)
  },
  {
    path: 'fuel-management',
    loadChildren: () => import('./main/fuel-management/fuel-management.module').then(m => m.FuelManagementModule)
  },
  {
    path: 'employee-tracking',
    loadChildren: () => import('./main/employee-tracking/employee-tracking.module').then(m => m.EmployeeTrackingModule)
  },
  {
    path: 'user-charges',
    loadChildren: () => import('./main/user-charges/user-charges.module').then(m => m.UserChargesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'leave-management',
    loadChildren: () => import('./main/leave-management/leave-management.module').then(m => m.LeaveManagementModule)
  },
  {
    path: 'internal-announcements',
    loadChildren: () => import('./main/internal-messaging/internal-messaging.module').then(m => m.InternalMessagingModule)
  },
  {
    path: 'state-dashboard',
    loadChildren: () => import('./main/state-dashbord/state-dashbord.module').then(m => m.StateDashbordModule)
  },
  {
    path: 'item-distribution',
    loadChildren: () => import('./main/item-distribution/item-distribution.module').then(m => m.ItemDistributionModule)
  },
  {
    path: 'payroll-management',
    loadChildren: () => import('./main/payroll-management/payroll-management.module').then(m => m.PayrollManagementModule)
  },
  {
    path: 'har-din-6-bin-management',
    loadChildren: () => import('./main/har-din6-bin/har-din6-bin.module').then(m => m.HarDin6BinModule)
  },
  {
    path: 'beats',
    loadChildren: () => import('./main/beats/beats.module').then(m => m.BeatsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'internal-incidents',
    loadChildren: () => import('./main/internal-incidents/internal-incidents.module').then(m => m.InternalIncidentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'state-profiling'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    LoginComponent,
    ImageDialogComponent,
    ThankYouComponent,
    QuestionListComponent,
    SlugifyPipe,
    DashboardComponent,
    DashboardPrototypeComponent,
    ConfirmUlbDialogComponent,
    AssignUlbDialogComponent,
    TempDashboardComponent,
    DashboardP5Component
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' },
    SlugifyPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
    TranslateModule.forRoot(),
    LeafletModule.forRoot(),
    ColorPickerModule,
    MatDialogModule,
    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    AngularMultiSelectModule,
    FormsModule,

    AgGridModule.withComponents([]),

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    SampleModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatStepperModule,
    ClipboardModule,
    ChartsModule,
    GaugeChartModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TreeviewModule.forRoot(),
    Ng5SliderModule,
    GridsterModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_KEY',
      libraries: ['places']
    }),
    MatCarouselModule.forRoot(),
    LeafletMarkerClusterModule
  ],
  exports: [RouterModule],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [ConfirmDialogComponent, ImageDialogComponent, QuestionListComponent,ConfirmUlbDialogComponent,AssignUlbDialogComponent]

})
export class AppModule {
}
