import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from 'app/service/api.service';


@Component({
  selector: 'web-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})

export class ConfirmDialogComponent {
  title: string;
  message: string;
  is_alert = false;
  showReasonField = false;
  reasonStatusList = [];
  reason = '';
  inputType = '';
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private getApiService:ApiService) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    if(data.is_alert != undefined){
      this.is_alert = data.is_alert
    }
    this.getAllReasonList();
    this.showReasonField = data.showReasonField;
    this.inputType = data.inputType;
  }

  onConfirm(): void {
    const result = this.showReasonField ? this.reason : true
    // Close the dialog, return true
    this.dialogRef.close(result);
  }

  getAllReasonList() {
    this.getApiService.get("employee_service_status").subscribe(res => {
      this.reasonStatusList = res
    })
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

  constructor(public title: string, public message: string,public is_alert?:boolean,public showReasonField?,public inputType?) {
  }
}


