<div class="navbar-top" style="height: 100px; max-height: 100px;" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo" style="display: inline-block;margin: 0 auto;">
        <!-- <img class="logo-icon" src="assets/images/logos/logo_swm.png"> -->
        <a [routerLink]="['/dashboard']" style="display: inline-block;"><img class="logo-icon" src="../../../../../../assets/images/logos/{{env.sideLogo}}"></a>
        <!-- <span class="logo-text secondary-text">SWM</span> -->
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>

<div class="navbar-bottom" style="background: #1E2129; color: #fff;padding: 10px 5px;" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo" style="text-align: center;" *ngIf="env && !env.hideBranding">
        <img class="logo-icon" style="width: 50%; display: block; margin: 0 auto;" src="../../../../../../assets/images/logos/ba_short.png">
        <small style="font-size: 10px;">POWERED BY BRAIN ABOVE INFOSOL PVT LTD</small>
    </div>

</div>
